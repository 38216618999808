import { Record } from 'immutable'
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

const  ReportProps = Record({
  id:null,
  uid:null,
  worked_date:null,
  drive_start_at : null,
  is_worked :0,
  work_start_at  :setMinutes(setHours(new Date(),0),0),
  work_end_at   :setMinutes(setHours(new Date(),0),0),
  area :null,
  load_location :null,
  delivery_location :null,
  detail :null,
  distance :null,
  meter_start :null,
  meter_end :null,
  requested_unit :null,
  delivery_unit :null,
  compleated_unit:null,
  nekopos_unit :null,
  nekopos_load_unit :null,
  dm_unit :null,
  dm_load_unit :null,
  normal_unit :null,
  normal_load_unit :null,
  night_unit :null,
  load_unit :null,
  large_unit :null,
  return_unit :null,
  return_requested_unit:null,
  waiting_hour :null,
  fee :null,
  fee_information:null,
  sales :null,
  high_way_cost :null,
  parking_cost :null,
  tatekae_cost :null,
  updated_at :null,
  created_at :null,
  checked_at :null,
  is_return :null,
  code:null,
  spot_type:null,
  note:null,
  received_gender:null,
  received_name:null,
  over_time_hour:null,
  over_time_fee:null,
  over_distance:null,
  project_id:null,
  project_driver_id:null,
  driver_name:null,
  project:null,
  project_driver:null,
  driver:null,
  receipts:[],
  project_type:null,
  driver_id:null,
  partner_id:null,
  search_year:null,
  search_month:null

});
export default ReportProps;


