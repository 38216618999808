import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import ProjectDelete from "./ProjectDelete"
import useProjectState from './useProjectState';
//Format
import useReportFormatState from '../monthly_report/format/usReportFormatState';
function ProjectDeleteApp(props:PropsValue) {
  const { id } = useParams();
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)
  const [formats, setFormats] = useState(null)
  const {project, project_error, project_deleted, getProject, deleteProject} = useProjectState()
  const{report_format_list,report_format_list_updated_at, getReportFormatList} = useReportFormatState()
  

  useEffect(() => {
    if(project){
      setItem(project)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project])


  useEffect(() => {
    if(report_format_list_updated_at && report_format_list){
      setFormats(report_format_list)
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_format_list_updated_at])

  useEffect(() => {
    if(token){
      getProject(token, id)
      getReportFormatList(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
    if(project_deleted){
      window.location.href = "/project/success/deleted"
    }
  },[project_deleted])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = () => {
    deleteProject(token, id)
  }
  
  return(
    <div id="home">
      <PageApp current_page="project"  content={<ProjectDelete token={token} project={item} formats={formats} error={project_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ProjectDeleteApp

