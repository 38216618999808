import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { PageApp } from '../../stories/PageApp'
import ReportList from "./ReportList"
import useReportState from './useReportState';

import { LoadingWindow } from '../../stories/components/loading_window/LoadingWindow';
import ReportSearchProps from "./ReportSearchProps"

function ReportListApp(props:PropsValue) {
  const { spot } = useParams();
  const project_type  = spot === "spot" ? 2 : 1
  const [page, setPage] = useState(null );
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(<LoadingWindow />)
  const [profile, setProfile] = useState(null)
  const [search_item, setSearchItem] = useState(new ReportSearchProps({project_type:project_type}))
  const { report_list, report_unchecked_list, pending_list, list_updated_at, checked_at, report_created, getUnCheckedList, getCheckedList, getPendingReportList, check, uncheck, createReport,deleteReport} = useReportState()


  useEffect(() => {
      console.log(report_list)
    if(report_list){
      setLoading(null)
    }

  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_list])


  useEffect(() => {

    if(token){
      getCheckedList(token,search_item.project_type,page,search_item.project_id, search_item.driver_id, search_item.start_date ? format(search_item.start_date, "yyyy-MM-dd") : null , search_item.end_date ? format(search_item.end_date, "yyyy-MM-dd") : null, search_item.detail, search_item.partner_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page])




  useEffect(() => {
   
    if(token){
    
      getPendingReportList(token, search_item.project_type, 2, search_item.project_id, search_item.driver_id, search_item.start_date ? format(search_item.start_date, "yyyy-MM-dd") : null , search_item.end_date ? format(search_item.end_date, "yyyy-MM-dd") : null, search_item.detail, search_item.partner_id)
      getUnCheckedList(token,search_item.project_type,page,search_item.project_id, search_item.driver_id, search_item.start_date ? format(search_item.start_date, "yyyy-MM-dd") : null , search_item.end_date ? format(search_item.end_date, "yyyy-MM-dd") : null, search_item.detail, search_item.partner_id)
      setPage(1) //uncheked list を取得
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token, checked_at, report_created])



  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  const searchHandler = (e, type) => {


   var _start_date = e.work_start_at  ? new Date(e.work_start_at ) :null
   var _end_date = e.work_end_at  ? new Date(e.work_end_at ) : null

   setSearchItem(
    ReportSearchProps({
      project_id:e.project_id,
      driver_id:e.driver_id,
      start_date:_start_date,
      end_date:_end_date,
      free_word:e.detail,
      project_type:type,
      partner_id:e.partner_id
    })
   )
   setPage(1)
   setLoading(<LoadingWindow />)
   getPendingReportList(token, type, 2, e.project_id, e.driver_id, _start_date ? format(_start_date, "yyyy-MM-dd") : null , _end_date ? format(_end_date, "yyyy-MM-dd") : null, e.detail, e.partner_id)
   getUnCheckedList(token,type,1,e.project_id, e.driver_id, _start_date ? format(_start_date, "yyyy-MM-dd") : null , _end_date ? format(_end_date, "yyyy-MM-dd") : null, e.detail, e.partner_id)
   getCheckedList(token,type,1,e.project_id, e.driver_id, _start_date ? format(_start_date, "yyyy-MM-dd") : null , _end_date ? format(_end_date, "yyyy-MM-dd") : null, e.detail, e.partner_id)
 
   //getList = (token, project_type, page, project_id, driver_id, start_date, end_date)
  }

  const checkHandler =(e) => {
    check(token,e.project.uid, e.driver.uid, e.id)
  }

  const unCheckHandler =(e) => {
    uncheck(token,e.project.uid, e.driver.uid, e.id)
  }

  const pendingHandler =(e) => {  
    createReport(token,e.project.uid, e.driver.uid, e)
  }
  
  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  const updateHandler =(e,command) => {  
    if(command === "delete"){
      deleteReport(token, e.project.uid, e.driver.uid, e.id )
    }else{
      createReport(token,e.project.uid, e.driver.uid, e)
    }
  }


  const  goToNext = () => {
    setPage(page + 1)
    
  }

  const  goToPrev = () => {
    setPage(page - 1)
    
  }

  return(
    <div id="home">
      <div>{loading}</div>
      <PageApp current_page="report" content={<ReportList  profile={profile} spot={spot} search_item={search_item} updated_at={list_updated_at}  token={token} unchchecked_list={report_unchecked_list} checked_list={report_list} pending_list={pending_list} current_page={page} goToNext={goToNext} goToPrev={ goToPrev} search={searchHandler} checkHandler={checkHandler} unCheckHandler={unCheckHandler} pendingHandler={pendingHandler} updateHandler={updateHandler}/>}
       authChangeHandler={authChangeHandler} 
       profileChangeHandler={profileChangeHandler} />
    </div>
     
  )
}

export default ReportListApp

