import React, { useEffect, useState } from 'react'

function BodyDefaultItem(props:PropsValue) {

  const [item, setItem] = useState(null)
  
  useEffect(() => {
    setItem(props.item)
 
  },[ props.item])


  const changeHandler = (e) =>{
    const target = e.target
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
    props.updateRowHadler(new_item)
  }
  return(
    <tr >  
      <td>{props.index + 1}{item && item.is_edit_all && props.editable ? <span className="delete-item" onClick={props.deleteItem}><i className="fa-regular fa-trash-can"></i></span> : null}</td>
      
      {item && item.is_edit_all && props.editable ?  <td className="w40"><input type="text" name="date" value={item.date} onChange={changeHandler} /></td> : item ? (<td className="w40">{item.date ? item.date : undefined}</td>) : <td className="w40"></td> }
      
      {item && item.is_edit_all  && props.editable?  <td className="w100"><input type="text" name="driver_name" value={item.driver_name} onChange={changeHandler} /></td> : item ? ( <td className="w100">{item.driver_name}</td>): <td></td>}
      
      {item ?
       props.editable ?  <td ><input type="text" name="detail" value={item.detail} onChange={changeHandler} /></td> :  
      <td>{item.detail}</td>
      : <td></td>
      }
      
      {item  && props.editable ? (<td className="w60"><input type="text" name="comment" value={item.comment} onChange={changeHandler} /> </td>) : <td className="w60"></td> }
      
      {item  & props.editable && item.is_non_tax ? 
        (<td className="w60"><input type="number" onWheel={(e) => e.target.blur()}  name="non_tax_price" value={item.non_tax_price} onChange={changeHandler} /></td>) 
        : item && props.editable ?  <td className="w60">{item.cost ? item.cost.toLocaleString(): null} </td> 
        : item ? <td className="w60">{item.cost ? item.cost.toLocaleString() : null} </td>
        : <td className="w60"></td>}
      
      
      
      {item && item.unit_name && props.editable ? 
        <td className="right w60"><input type="number" name="unit_number" onWheel={(e) => e.target.blur()}  className="half-input" value={item.unit_number} onChange={changeHandler} /> {item.unit_name} </td>: item && item.unit_name ?  
        <td className="right w60">{item.unit_number ? item.unit_number.toLocaleString(): 0}{item.unit_name}</td>
        : item && props.editable ? <td className="right w60"><input type="text"  name="fee_text_3" value={item.fee_text_3} onChange={changeHandler} /> </td> 
        : item ? <td className="right w60">{item.fee_text_3}</td>
        : <td className="right w60"></td>}
      
      {item  && !item.is_unit_price && !item.is_tax_rate ?
       <td className="right">{item.unit_price ? item.unit_price.toLocaleString(): undefined}{item.unit_cost_name}</td>
       : item && item.is_unit_price  && props.editable ? <td><input type="number" onWheel={(e) => e.target.blur()}  name="unit_price" value={item.unit_price ? item.unit_price : undefined} onChange={changeHandler} /></td>
       : item && item.is_tax_rate && props.editable  ? 
        <td > 
          <select  className="small" name="tax_rate" value={item.tax_rate} onChange={changeHandler} >
            <option value={0}>-</option>
            <option value={10} selected={item.tax_rate === 10}>10%</option>
            <option value={8} selected={item.tax_rate === 8}>8%</option>
            <option value={100} selected={item.tax_rate === 100}>非課税</option>
          </select>
         
        </td>
       : <td></td>
      }
      {item && item.is_edit_all  && props.editable ?  <td className="right w80"><input type="number" onWheel={(e) => e.target.blur()} name="total_price" value={item.getTotal() } onChange={changeHandler} /></td> : item ? ( <td className="right w80">{item.getTotal() && item.getTotal() !== 0 ? item.getTotal().toLocaleString() : "0"}</td>): <td></td>}
  </tr>
    
  )
}

export default BodyDefaultItem
