import React, { useEffect, useState , useCallback} from 'react'
import "./logo.css"
function LogoUploader(props:PropsValue) {

  const [displayImage, setDisplayImage] = useState(null);


  useEffect(() => {
    
    setDisplayImage(props.logo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.logo])

  
  const handlerChangeImageFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) {
      return;
    }
    // 選択されたファイルが画像ファイル以外だったらreturn
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      // バリデーションメッセージ表示
      console.log('jpeg/pngファイルを選択してください');
      return;
    }
    const reader = new FileReader();
    // base6４に変換
    reader.readAsDataURL(file);
    reader.onload = () => {
      // base64に変換した結果をstateにセットする
      props.imageChangeHandler(reader.result);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return(
    <div className="logouploader">
        <input type="file" onChange={handlerChangeImageFileInput} />
        <div className="thum">
           {
          displayImage ? 
          <img
            src={displayImage}
            alt=""
          />
         : null
        
        }
        </div>
		</div>
    
  )
}

export default LogoUploader
