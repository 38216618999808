import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../../stories/components/breadcrumbs/LinkProps';
import config from 'react-global-configuration';
import {isDate,  isBefore } from 'date-fns';
//project 
import CreateProject from '../projectObject/CreateProject';
//parter
import usePartnerState from '../../partners/usePartnerState';
//driver 
import useDriverState from '../../drivers/useDriverState';
//report 
import useReportState from '../../report/useReportState';
import ProjectDriverDetailItem from './ProjectDriverDetailItem';




function ProjectDriverDetail(props:PropsValue) {
  

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  const [projectObject, setProjectObject] = useState(null)
  const [report_url, setReportUrl] = useState(null)


  const [partners, setPartners] = useState([])
  const [partner_def, setParnerDef] = useState(null)
  const {partner_list, getPartnerList}= usePartnerState()

  const [drivers, setDrivers] = useState([])
  const {driver_list, getDriverList}= useDriverState()
  const [driver_def, setDriverDef] = useState(null)

  const {getReportUrl} = useReportState()
  //const {getEndDateByType} = useProjectState()
  
  useEffect(() => {
   
    if(props.project_driver){

      setItem(props.project_driver)
      getPartnerList(props.token, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project_driver])

  useEffect(() => {

    if(item  && item.partner_id && partner_list) {
      const partner = partner_list.find((_item) => Number(_item.id) === Number(item.partner_id))
      if(partner){
        setParnerDef({value:partner.id, label:partner.name})
        getDriverList(props.token, 1, Number(partner.id))
      }
    }
    if(partner_list){
      setPartners(partner_list.map(item => {
        return {value:item.id, label:item.name}
      }))
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner_list])




  useEffect(() => {

    if(props.project){
      //const project = new Project(props.project)
      setProjectObject(CreateProject.create(props.project))

      if(props.project_driver && props.project_driver.id && props.project ){
        setReportUrl(getReportUrl(props.project.uid, props.project_driver.uid, "new"))
         //crate report link 
      }
    }
      
    /**{
      props.monthly_reports && props.project ? props.monthly_reports.reverse().map(item => {
        
      })
     : null } **/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project, props.project_driver, props.monthly_reports])


  useEffect(() => {
    
    if(item  && item.driver_id && driver_list) {
      const driver = driver_list.find((_item) => Number(_item.id) === Number(item.driver_id))
      if(driver){
        setDriverDef({value:driver.id, label:driver.name})
      }
    }
    
    if(driver_list){
      setDrivers(driver_list.map(item => {
        return {value:item.id, label:item.name}
      }))
    }
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver_list])

  
  const changeHandler = (e) => {
    const target = e.target
    setItem(item.set(target.name, target.value))
  } 

  const changeFeeHandler = (e) => {
    const target = e.target

    if(item.fee_information){
      setItem(item.setIn(["fee_information",target.name], target.value))
    }else{
      var new_item = item.set("fee_information", {})
      setItem(new_item.setIn(["fee_information",target.name], target.value))
    }
  }

  const btnClickHandler = () => {
    const errors = getErrorMessagte()
    setErrorMessage(null)
    if(errors.length >0 ){
      setErrorMessage(errors.map(item => <div>{item}</div>))
    }else{
      props.updateHandler(item)
    }
  }


  /**
  const createMonthlybtnClickHandler = () => {
      const target = getTargetYearAndMonth(getEndDateByType(props.project.closing_date))
      window.location.href ="/project/" + props.project.uid + "/monthly_report/" + props.project_driver.uid + "/"  + target[0] + "/" + target[1]
  } */

  const getErrorMessagte = () => {
    var errors = projectObject.validateForm(item)
  
    if(!item.driver_id || item.driver_id === "") errors.push("ドライバー名が未入力です。")
    if(!item.start_date || item.start_date === "") errors.push("案件開始日が未入力です。" )
    if(item.start_date && (!isDate(new Date(item.start_date)) && isBefore(new Date(item.start_date), new Date("2020-01-01"))) ) errors.push("案件開始日が不正です。")
    if(item.end_date && (isDate(new Date(item.end_date)) && isBefore(new Date(item.end_date), new Date("2020-01-01")))) errors.push("案件終了日が不正です。")

    if(projectObject.is_unit_price && (item.fee_information.unit_price === "" || item.fee_information.unit_price === null)) errors.push("支払い単価が未入力です")
    if(projectObject.category === 4 && (item.fee_information.unit_yamato_normal === 0 || item.fee_information.unit_yamato_normal === null || item.fee_information.unit_yamato_nekopos === 0 || item.fee_information.unit_yamato_nekopos === null || item.fee_information.unit_yamato_dm === 0 || item.fee_information.unit_yamato_dm === null )) errors.push("支払い単価が未入力です")
    if(projectObject.project_type === 1 &&  !item.sunday &&  !item.monday &&  !item.thuesday &&  !item.wednesday &&  !item.thursday &&  !item.friday &&  !item.saturday ) errors.push("日報チェックが未入力です。")

    return errors
  }

  const loadPartnerOptions = (e) => {
    getPartnerList(props.token, 1, {name:e})
  }

  const changePartnerHandler = (e) => {
    setItem(item.set("partner_id", Number(e.value)))
    setParnerDef(e)
    getDriverList(props.token, 1, Number(e.value))
  } 


  const loadDriverOptions = (e) => {
    getDriverList(props.token, 1, item.partner_id, {name:e})
  }
  
  const changeDriverHandler = (e) => {
    setItem(item.set("driver_id", Number(e.value)))
    setDriverDef(e)
  } 

  const createdNewDriverHandler = (d) => {
    setItem(item.set("driver_id", Number(d.id)))
    getDriverList(props.token,1,d.partner_id)
  }


  return(
    <div className="inner">
       <form >
			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">{item && item.id ? "担当ドライバー情報" : "担当ドライバー追加"}</h2>
                <Breadcrumbs pages={[new LinkProps("案件一覧","/project"),new LinkProps(item && item.id ? "担当ドライバー情報" : "担当ドライバー追加","#")]} />
							</div>
							
							<div className="container">
             
                <div className="column"><p className="container_name">{item && item.id ? "担当ドライバー情報" : "担当ドライバー追加"}</p>
                {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                {item && projectObject?
                  <div className="input_wrap">
                    <ProjectDriverDetailItem 
                      projectObject={projectObject}
                      report_url={report_url}
                      item={item} 
                      changeHandler = {changeHandler}
                      changeFeeHandler = {changeFeeHandler}
                      changeDriverHandler={changeDriverHandler} 
                      changePartnerHandler={changePartnerHandler} 
                      loadDriverOptions = {loadDriverOptions} 
                      loadPartnerOptions={loadPartnerOptions}
                      partners = {partners}
                      partner_def = {partner_def}
                      drivers = {drivers}
                      driver_def = {driver_def}
                      is_edit={props.profile && (props.profile.user_type === config.get("USER_FINANCE") || (item && !item.id) )}
                      createdNewDriverHandler = {createdNewDriverHandler}
                      token = {props.token}
                    />
                  </div>
                : null}
                {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                {props.profile && (props.profile.user_type === config.get("USER_FINANCE") || (item && !item.id)  ) ?
                  <div className="button_wrap center">
                    <button className="red" type="button" onClick={btnClickHandler}>登録内容確認</button>
                  </div>
                : null}
                </div>
              </div>
             
				</div>
       </form>
		</div>
    
  )
}

export default ProjectDriverDetail

