import React, {useState, useEffect  } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import ProjectDetail from "./ProjectDetail"
import ProjectProps from './ProjectProps';
import useProjectState from './useProjectState';
//import useProjectDriverState from './driver/useProjectDriverState';

function ProjectDetailApp(props:PropsValue) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const is_new = searchParams.get("new") ?  searchParams.get("new") : 0 ;
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)
  const [has_cookie, setHasCookie] = useState(false)

  const {project, saveOnCookie, getProject, hasCookie, loadFromCookie} = useProjectState()

 //// const {my_project_list, getListByProject} = useProjectDriverState()
  useEffect(() => {

    if(token){
      setHasCookie(hasCookie())
      if(is_new === 0 && hasCookie()){
        loadFromCookie()
      }else{
        if(id === "new"){
          setItem(new ProjectProps())
        }else{
          getProject(token, id)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  

  useEffect(() => {
    if(project){
      
      //setDrivers()
      setItem(new ProjectProps(project))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project])

  
  const authChangeHandler = (e) => {
    if(e.error){
     
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    saveOnCookie(_item)
    window.setTimeout( function(){
      window.location.href = "/project/confirm"
    },500)

  }

  const saveOnCookieHandler = (item) => {
    saveOnCookie(item)
    window.setTimeout(function(){
       window.location.href = "/project"
    },500)
   
  }

  
  return(
    <div id="home">
      <PageApp current_page="project"  content={<ProjectDetail  token={token} has_cookie={has_cookie}  drivers={project ? project.drivers : []} project={item} updateHandler={updateHandler} saveOnCookieHandler={saveOnCookieHandler} loadFromCookie={loadFromCookie}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ProjectDetailApp

