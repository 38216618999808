import React, { useEffect, useState } from 'react'

import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import { Pageing } from '../../stories/components/pageing/Pageing';
import { SearchDriver } from '../../stories/components/searchbox/SearchDriver';
import DriverProps from './DriverProps';

import { ProjectListWindow } from '../../stories/components/project_window/ProjectListWindow';
import DriverDetailOverlay from './DriverDetailOverlay';

import useReportState from '../report/useReportState';

function DriverList(props:PropsValue) {

  const [list, setList] = useState(null)
  const [total_number, SetTotalNumber] = useState(1)
  const [detail, setDetail] = useState(null)
  const [message_id, setMessageId]= useState(null)
  const {getReportForMyDriverUrl} = useReportState()
  
  const clearHandler = () => {
    window.location.href = "/driver"
  }


  useEffect(() => {
    constCreateTbody()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.drivers])

  useEffect(() => {
    if(props.company){
      constCreateTbody()
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.company])


  useEffect(() => {
    constCreateTbody()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project_list,message_id])
  
  useEffect(() => {
 
    SetTotalNumber(props.total_number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.total_number])
  
    

  
  const btnClickHandler = (e, with_deleted) =>{
    props.search(e,with_deleted)
  }


  const getProject = (id, driver_uid) => {
    const projects = props.project_list.filter(item => {
      const _driver_ids = item.drivers.map(d => {return (d.driver_id)} )

      if(_driver_ids.findIndex(_id => Number(_id) === Number(id)) >= 0 ) {
        return item
      }
      else return null 
   
    }).filter(item => item)
    
    return <div onClick={()=> displayDetail(projects,driver_uid)}>{projects.length}件</div>
  }

  const displayDetail = (projects, driver_id) => {
    setDetail(<ProjectListWindow projects={projects}  driver_id={driver_id} closeHandler={closeHandler}  />)
  }
  const displayDetailInfo = (item) => {
    setDetail(<DriverDetailOverlay token={props.token} projects={props.project_list}  profile={props.profile}  driver={item} closeHandler={closeHandler}  />)
  }
  



  const closeHandler = () => {
    setDetail(null)
  }


 const constCreateTbody = () => {
    setList(
      props.drivers.map(item => {
        //TODO ここ
      
        const url =  Number(props.inhouse) === 1  && props.company ? getReportForMyDriverUrl(props.company.project_id, props.company.project_driver_id, item.uid) :null
        return(
          <tr key={"item_" + item.id}>
       
					  <td>{item.id}</td>
            <td>{item.partner.name}</td>
						<td>{item.name}</td>
						<td>{item.user.name}</td>
            
						<td>{item.tel}</td>
						<td>{item.car_no}</td>
            <td>{getProject(item.id, item.uid)}</td>
            {Number(props.inhouse) === 1 ? 
            <td>
              {url ? <span><span className="link" onClick={()=> copy(url, item.id)}><i className="fa-regular fa-copy"></i></span> </span>: null}
              {message_id === item.id ? <span className="copyied">コピーしました。</span> : null}
            </td>: null}
           
            <td>
							<div className="buttons">
                <div className="textbutton" onClick={() => displayDetailInfo(item)}>詳細確認</div>
							</div>
							</td>
					</tr>
        )
      })
    )
  }


  const moveToList = (type ) => {
    if(type === "regular" && props.inhouse === "1"){
      window.location.href = "/driver?inhouse=0"
    }else if (type === "inhouse"  && props.inhouse !== "1"){
      window.location.href = "/driver?inhouse=1"
    }
  }

  const copy = (u,id) => {
    console.log(u)
    navigator.clipboard.writeText(u).then(  () => {}, () => {});
    setMessageId(id)
   
  }


  return(
    <div className="inner">

         {detail ?  <div id="overlay" className="overlay"  onClick={closeHandler}></div> : null}
			  <div className="content_wrap">
					  <div className="page_title_wrap">
								<h2 className="page_title">ドライバー一覧</h2>
                <Breadcrumbs pages={[new LinkProps("ドライバー一覧","#")]} />
						</div>
            {props.partner_id ? null : 
              <a href="/driver/new?new=1" className="button red">ドライバー新規作成</a>
            }
            {props.partner_id ? null : 
            <SearchDriver token={props.token} data={new DriverProps()} btnClickHandler={btnClickHandler} clearHandler={clearHandler} />
            }
            {props.partner_id ? null : 
            <div className="tab-box">
              <div className={props.inhouse === "1"  ? "tab-item selected" : "tab-item" }  onClick={() => moveToList("inhouse")}>自社</div>
              <div className={props.inhouse !== "1"  ? "tab-item selected" : "tab-item" } onClick={() => moveToList("regular")} >協力会社</div>  
            </div>
            }
            <table className="datatable">
							
							<thead>
                <tr>
									<th>No.</th>
                  <th>所属企業</th>
									<th>ドライバー名</th>
									<th>担当マネージャー</th>
									<th>電話番号</th>
									<th>車両番号</th>
                  <th>稼働案件</th>
                  {props.inhouse === "1" ? <th>日報入力フォーム</th>: null}
									<th>編集/削除</th>
								</tr>
							</thead>
							<tbody>
              {list}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan="99">
                    <Pageing total_number={total_number} current_page={props.current_page} limit={props.search_limit} additional_params={["inhouse=" + props.inhouse]}/>
										
									</td>
								</tr>
							</tfoot>
						</table>
				</div>
        {detail}
		</div>

  )
}

export default DriverList


/**
 *  <a href={"/driver/" + item.id} className="icon_button"><i className="fa-solid fa-pen"></i></a>
                <a href={"/driver/" + item.id + "/delete"} className="icon_button"><i className="fa-solid fa-trash"></i></a>
 */