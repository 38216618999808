import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { PageApp } from '../../stories/PageApp'
import DriverList from "./DriverList"
import useDriverState from './useDriverState';
import useProjectState from '../project/useProjectState';
import useCompanytState from '../company/useCompanyState';
import { LoadingWindow } from '../../stories/components/loading_window/LoadingWindow';
import Driver from './Driver';

function PartnerListApp(props:PropsValue) {
  const [searchParams] = useSearchParams();
  const { partner_id } = useParams();

  
  const page = searchParams.get("page") ?  searchParams.get("page") : 1 
  const [loading, setLoading] = useState(null)
  const [inhouse, setInHouse] = useState(searchParams.get("inhouse") ?  searchParams.get("inhouse") : "1" );
  const [token, setToken] = useState(null)
  const [list, setList] = useState([])
  const [projects, setProjects] = useState([])
  const [profile, setProfile] = useState(null)
  const [total_number, setTotalNumber] = useState(1)
  const {driver_list, list_updated_at,driver_total_number,driver_search_limit,  getDriverList} = useDriverState()
  const {project_list, getProjectList} = useProjectState()
  const {company, getMyCopmpanyDetail} = useCompanytState()

  useEffect(() => {
    
    setLoading(<LoadingWindow closeHandler={loadingCloseHanlder} ></LoadingWindow>)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(() => {
    if(driver_total_number > 1){
      setTotalNumber(driver_total_number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver_total_number])


  useEffect(() => {
    if(project_list && project_list.length > 0){
      setProjects(project_list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_list])

  useEffect(() => {
    if(driver_list ){
      setLoading(null)
      const driver_ids = driver_list.map(item => item.id)
      setList(driver_list)
      getProjectList(token,1,driver_ids)
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])

  useEffect(() => {

    if(token){
     
      
      //GET LIST
      if(partner_id){
        getDriverList(token,page, partner_id, null, null, 0)
      }else{
        getDriverList(token,page, null, new Driver({is_internal:inhouse}), null, 0 )
      }
      //自社の場合は会社情報（自社project/project_driver)取得　
      if(Number(inhouse) === 1){
        getMyCopmpanyDetail(token)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])


  
  const authChangeHandler = (e) => {
   
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  const loadingCloseHanlder = ()=>{
    setLoading(null)
  }


  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  const searchHandler = (e, with_deleted) => {
    var search_form = e
    if(e.partner_id ){
      if(Number(e.partner_id) === Number(profile.company.id)){
        search_form = e.set("is_internal", 1)
        setInHouse(1)
      }else{
        search_form = e.set("is_internal", 0)
        setInHouse(0)
      }   
    
    }else{
      search_form = e.set("is_internal", inhouse)
    }
    setLoading(<LoadingWindow closeHandler={loadingCloseHanlder} ></LoadingWindow>)
    getDriverList(token,1, null, search_form, with_deleted)
  }  

  return(
    <div id="home">
      {loading}
      <PageApp current_page="driver" content={<DriverList project_list={projects} inhouse={inhouse} company={company} profile={profile} search_limit={driver_search_limit}  partner_id={partner_id} token={token} drivers={list} total_number={total_number} current_page={page} search={searchHandler} />} authChangeHandler={authChangeHandler} profileChangeHandler={profileChangeHandler}/>
    </div>
     
  )
}

export default PartnerListApp

