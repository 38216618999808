import { useState } from 'react';
import MonthlyPaymentAction from './MonthlyPaymentAction';



function useMonthlyPaymentState() {

  
  const [error, setError] = useState(null)
  const [created, setCreated] = useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState([])
  const [total_number, setTotalNumber] = useState(null)
  const [list_updated_at, setListUpdatedAt] = useState(null)

  const listLoadeddHandler = (data) => {
    setList(data.data)
    setListUpdatedAt(new Date())
    setTotalNumber(data.data.length)
  }

  

  const loadedHandler = (data) => {
    setItem(data.data)
  }
  const createdHandler = (data) => {
    setItem(data.data)
    setCreated(data.data)
  }

  const errorHandler = (data) => {
    setError(data)
  }
  
  const hasCookie = () => {
    return MonthlyPaymentAction.hasCookie()
  }

  const removeCookie = () => {
    MonthlyPaymentAction.removeCookie()
  }

  const saveOnCookie = (item) => {
    MonthlyPaymentAction.saveOnCookie(item)
  }

  const loadFromCookie = () => {
    MonthlyPaymentAction.loadFromCookie( loadedHandler, errorHandler)
  }
  

  const getList = (token,  partner_id, year, month, partner_ids,) => {
    MonthlyPaymentAction.getList(token, partner_id, null, year, month, partner_ids, listLoadeddHandler, errorHandler)
  }

  const getListByDriver = (token,  partner_id,driver_id, year, month,) => {
    MonthlyPaymentAction.getList(token, partner_id, driver_id, year, month, null, listLoadeddHandler, errorHandler)
  }

  const create = (token, partner_id, item) => {
    
    MonthlyPaymentAction.create(token, partner_id, item, createdHandler, errorHandler)
  }

  const confirm = (token,  id, is_confirm, is_return,  comment) => {

    MonthlyPaymentAction.confirm(token, id, is_confirm, is_return,  comment, createdHandler, errorHandler)
  }

 
  const getDetail = (token, id) => {
    MonthlyPaymentAction.getDetail(token, id, loadedHandler, errorHandler)
  }

  const deleteItem = (token,project_id, driver_id, id) => {
    MonthlyPaymentAction.delete(token, id, createdHandler, errorHandler)
  } 

  const saveSearchParams= (item) => {
    MonthlyPaymentAction.saveSearchParams(item)
  }

  const loadSearchParams = () => {
    return MonthlyPaymentAction.loadSearchParams()
  }


  const deleteSearchParams = () => {
    return MonthlyPaymentAction.deleteSearchParams()
  }


 


  return { monthly_payment:item, monthly_payment_list:list,  monthly_list_updated_at:list_updated_at, monthly_payment_error:error, monthly_payment_deleted:created, monthly_payment_created:created, monthly_payment_total_number:total_number,
    getMontylyPaynmentList:getList, getMontylyPaynmentListByDriver:getListByDriver, getMontylyPayment:getDetail, createMonthlyPayment:create, deleteMonthlyPayment:deleteItem, 
    saveOnCookie, loadFromCookie, hasCookie:hasCookie, removeCookie:removeCookie,saveSearchParams,loadSearchParams,deleteSearchParams,
    confirm
  };
}

export default useMonthlyPaymentState