import React, {  } from 'react'

function HeaderDefault(props:PropsValue) {

  /**
   * year
   * mnth
   * project
   * project_driver
   * report list
   */

  
  return(
    <div>
       <h3 className="report_ttl">定期便 稼働日報</h3>
        <p className="report_manth">稼働年月：{props.year}年{props.month}月</p> <br/>
        <p className="report_driver">Dr名：{props ? props.driver.name : null}</p>
        <p className="report_casename">{props ? props.project.name : null} </p>
        <p className="report_period">稼働日数：{ props.repot_list ? props.repot_list.length :  null}日間</p>
		</div>
    
  )
}

export default HeaderDefault

