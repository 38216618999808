import React, {useState,useEffect} from 'react';
import Select from 'react-select';
import useUserState from '../../../components/user/useUserState';
import Utile from '../../../utiles/Utile';

interface ManagerSearchBoxProps {

    token:string,
  /**
    * changeHandler
    */
   changeHandler:Function,

   selected:?number
}

/**
 * Primary UI component for user interaction
 */
export const ManagerSearchBox = ({
  token,
  changeHandler,
  selected,
  ...props
}: ManagerSearchBoxProps) => { 

  const [managers, setManagers] = useState(null)
  const {user_list, getUserList} = useUserState()
  const [default_value, setDefaultValue] = useState(null)
  useEffect(() => {
    getUserList(token, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])


  useEffect(() => {
    if(user_list){
      const _list = [{value:0, label:"選択してください"}]
      setManagers(_list.concat(user_list.map(item => {
        if(item.user_type === "finance" || item.user_type === "manager" ){
          return {value:item.id, label:item.name}
        }else{
          return null
        }
        
        //return(<option value={item.id}>{item.name}</option>)
      })).filter(item => item !== null))

      const _def = user_list.filter(item => item.id === Number(selected))
      if(_def.length > 0){
        setDefaultValue({value:_def[0].id, label:_def[0].name})
      }
    }
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user_list, selected])

  const loadManagerOptions = (e) => {
    getUserList(token,1,{name:e})
  }


  const onChangeHandler = (e) => {
    changeHandler(e)
    setDefaultValue(e)
  }
  return (
    <Select
    placeholder= "選択してください"
    options={managers}
    loadOptions={loadManagerOptions}
    noOptionsMessage={()=>"該当するマネージャーが見つかりません"}
    onChange={onChangeHandler}
    styles={Utile.getDefaultSelectStyle()}
    defaultValue = {default_value}
    value = {default_value}

    />
    
  );
  
};

