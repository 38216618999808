import React, { useEffect, useState } from 'react'
import { format, parseISO,addDays,isSameDay } from 'date-fns';

import useReportState from '../../report/useReportState';
function BodySTSL(props:PropsValue) {
  
  const [list, setList] = useState(null)
  const [total, setTotal] = useState(0)
  const [hw_total, setHwTotal] = useState(0)
  const [pk_total, setPkTotal] = useState(0)
  const [ot_total, setOtTotal] = useState(0)
  const [other_total, setOtherTotal] = useState(0)
  const [days, setDays] = useState(0)
  const [repot_list , setReportList] = useState([])
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())
  const {getHighwayCost,getParkingCost,getOtherCost} = useReportState()
 
  useEffect(() => {
    setReportList(props.repot_list)
    setRepoListUpdatedAt(new Date())
 
  },[ props.repot_list, props.repo_list_updated_at])

  useEffect(() => {
    
    if(repot_list ){
       
        var days_total = 0
        var highway_total = 0
        var parking_total = 0
        var other_total = 0
        var fee_real_total = 0

        const _list = new Array(props.days + 1).fill(0).map((d,index) => {

          const target_day = addDays(props.start_date, index)
          const item = repot_list.find(item => isSameDay(parseISO(item.worked_date),target_day ))
          var highway_cost = 0
          var parking_cost = 0
          var other_cost = 0
          var fee_real = 0 
    
          
          if(item){
    
          
            fee_real = item.getRealFee()
      
            highway_cost = getHighwayCost(item.receipts, item.fee_information ? item.fee_information.highway_fee: 0)
            parking_cost = getParkingCost(item.receipts, item.fee_information ?item.fee_information.parking_fee: 0)
            other_cost = getOtherCost(item.receipts, item.fee_information ? item.fee_information.other_fee: 0)
            

            highway_total += (highway_cost )
            parking_total += parking_cost
            other_total += other_cost 
            fee_real_total += Number(fee_real ? fee_real : 0)
            days_total ++
            setDays(days_total)
            setHwTotal(highway_total)
            setPkTotal( parking_total )
            setOtTotal(other_total)
            setTotal(fee_real_total)
            setOtherTotal(highway_total + parking_total + other_total)
          }
     
          return(
            <tr className={item ? "link" : null} >
              {props.is_public ||!props.has_edit_right  ? null : <td>{item ? <input type="checkbox" checked={props.selected_list.find( s => (item && s === item.id)) ? true : false} onClick={() => props.checkHandler(item.id)}/> : null}</td>}
              <td onClick={() => item? props.changeHandler(item.id) :null}>{format(target_day, "dd")}</td>
              <td onClick={() => item? props.changeHandler(item.id) :null}>{item  ? item.load_location : null}</td>
              
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.delivery_location}</td>) : <td></td> }
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{format(parseISO(item.work_start_at),"H:dd")}</td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{format(parseISO(item.work_end_at),"H:dd")} </td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.distance.toLocaleString()} <span className="km">km</span></td>) : <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{highway_cost.toLocaleString()}</td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{parking_cost.toLocaleString()}</td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{other_cost.toLocaleString()}</td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{fee_real.toLocaleString()}</td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.note}</td>): <td></td>}
            </tr>
          )
        })
     
      setList(_list)
      
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ repo_list_updated_at,props.selected_at])




  return(
    <div className="body-STSL">
    <table>
      <tbody>
      <tr>
      {props.is_public || !props.has_edit_right? null : <th><input type="checkbox" onChange={props.checkAll}/></th>}
        <th>日</th>
        <th>引取先</th>
        <th>荷下し先</th>
      
        <th>発時</th>
        <th>完時</th>
        <th>距離</th>
        <th>高速代</th>
        <th>駐車場代</th>
        <th>その他</th>
        <th>売上運賃</th>
        <th>備考</th>
      </tr>
      {list}
      </tbody>
    </table>
    <div className="total">
      <div>稼働日数 <span>{days.toLocaleString()}</span></div>
      <div>駐車場立替 <span>{hw_total.toLocaleString()}</span></div>
      <div>立替金合計 <span>{other_total.toLocaleString()}</span></div>
      <div>高速代立替 <span>{pk_total.toLocaleString()}</span></div>
      <div>その他立替 <span>{ot_total.toLocaleString()}</span></div>
      <div>運賃合計 <span>{total.toLocaleString()}</span></div>
      <div>備考 </div>
    </div>
    <div className="bottom">
      *毎月月末日に03-6659-4516（（株）オーシャンズ）までFAXを送付お願いします
    </div>
    </div>
  )
}

export default BodySTSL

