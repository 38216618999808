
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../../action/Action';

const ReportAction = {


  getList(token, project_type, offset, check, project_id = null , driver_id = null , start_date = null , end_date = null , free_word = null, partner_id = null, is_all = null, is_id_only = null, callback, errorHandler){

    const data = new FormData() 

    data.append('limit', 0)
    data.append('offset', offset)
    data.append('check', check)
    if(project_type )
      data.append('project_type', project_type)
    if(project_id )
      data.append('project_id', project_id)
    if(driver_id )
      data.append('driver_id', driver_id)
    if(start_date )
      data.append('start_date', start_date)
    if(end_date )
      data.append('end_date', end_date)
    if(free_word )
      data.append('free_word', free_word)
    if(partner_id )
      data.append('partner_id', partner_id)
    if(is_all)
      data.append('is_all',is_all)
    if(is_id_only)
      data.append('is_id_only',is_id_only)

    
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_REPORT") + "?"  + queryString

    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },


  getListByPartners(token, start_date, end_date,  partner_ids, is_id_only,  callback, errorHandler){
    const data = new FormData() 
    data.append('start_date', start_date)
    data.append('end_date', end_date)
    data.append('is_id_only', is_id_only)
    data.append('partner_ids', partner_ids.join(","))
    
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_REPORT") + "?"  + queryString

    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },


  getListByPartner(token,  start_date, end_date, partner_id, is_all, callback, errorHandler){
    const data = new FormData() 
    data.append('limit', 0)
    data.append('offset', 0)
    data.append('start_date', start_date)
    data.append('end_date', end_date)
    data.append('partner_id', partner_id)
    data.append('is_simple', true)
    if(is_all)
      data.append('is_all',is_all)
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_REPORT") + "?"  + queryString
    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },

  getPublicList(  project_id  , driver_id , start_date , end_date , callback, errorHandler){
    const data = new FormData() 
 
    data.append('project_id', project_id)
    data.append('driver_id', driver_id)
    data.append('start_date', start_date)
    data.append('end_date', end_date)
    
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_REPORT") + "pub?"  + queryString

    Action.fetchJson( url , "GET", {}, null,  callback, errorHandler)
  },

  getPublicListByMonth(  project_id  , driver_id , year , month , callback, errorHandler){
    const data = new FormData() 
 
    data.append('project_id', project_id)
    data.append('driver_id', driver_id)
    data.append('month', month)
    data.append('year', year)
    
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_REPORT") + "pub?"  + queryString

    Action.fetchJson( url , "GET", {}, null,  callback, errorHandler)
  },



  getListByMonth(token, project_id, driver_id, year, month, is_all, callback, errorHandler){
  
    const data = new FormData() 
    data.append('project_id', project_id)
    data.append('driver_id', driver_id)
    data.append('year', year)
    data.append('month',month)
    data.append('is_all',is_all)
    
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_REPORT") + "?"  + queryString

    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },

  getPendingList(token, project_type, is_return , project_id = null , driver_id = null , start_date = null , end_date = null , free_word = null, partner_id, callback, errorHandler){
    const data = new FormData() 
   
    data.append('is_return', is_return)
    if(project_type )
      data.append('project_type', project_type)
    if(project_id )
      data.append('project_id', project_id)
    if(driver_id )
      data.append('driver_id', driver_id)
    if(start_date )
      data.append('start_date', start_date)
    if(end_date )
      data.append('end_date', end_date)
    if(free_word )
      data.append('free_word', free_word)
    if(partner_id )
      data.append('partner_id', partner_id)
    
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_REPORT") + "?"  + queryString

    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },
  

  getDetail(token, id,  callback, errorHandler){
    Action.fetchJson( config.get("API_PATH") + config.get("API_REPORT")  + id  , "GET", {}, null,  callback, errorHandler)
  },


  check( token, project_id, driver_id, id,  callback, errorHandler){
    const method = "PATCH"
    const url = config.get("API_PATH") + config.get("API_REPORT") + project_id + "/" + driver_id + "/" + id  + "/check"
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify({}),  callback, errorHandler)
  },

  unCheck( token, project_id, driver_id, id,  callback, errorHandler){
    const method = "PATCH"
    const url = config.get("API_PATH") + config.get("API_REPORT") + project_id + "/" + driver_id + "/" + id  + "/uncheck"
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify({}),  callback, errorHandler)
  },

  create( token, project_id, deiver_id, item,  callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const url = item.id ? config.get("API_PATH") + config.get("API_REPORT") + project_id + "/" + deiver_id + "/" + item.id  : config.get("API_PATH") + config.get("API_REPORT") + project_id + "/" + deiver_id
    
    item.id ?  Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)  :  Action.fetchJson(url ,  method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
   
  },

  delete( token, id, project_id, deiver_id, callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_REPORT")  + project_id + "/" + deiver_id + "/" + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },

  saveOnCookie(item){
    cookie.save('report_form',item, { path: '/' })
  },

  loadFromCookie( callback, errorHandler){
    const driver =  cookie.load("report_form")
   
    if(driver) {
      callback({data:driver})
    }else{
      errorHandler({"error":"not found"})
    }
  },

  deleteSearchParams(){
    cookie.save('report_search_param', { path: '/' })
  },

  saveSearchParams(item){
    cookie.save('report_search_param',item, { path: '/' })
  },

  loadSearchParams( callback, errorHandler){
    const params =  cookie.load("report_search_param")
    return params
  },

  hasCookie(item, callback, errorHandler){
    const project_form =  cookie.load("report_form")
    if(project_form) return true 
    return false
  },
  removeCookie(item, callback, errorHandler){
    cookie.remove('report_form', { path: '/' })
  }
 
 
}

export default ReportAction
