import React, {useState, useEffect  } from 'react'
import { useSearchParams } from "react-router-dom";
import format from 'date-fns/format';
import { PageApp } from '../../stories/PageApp'
import ReportMyList from "./ReportMyList"
import useProjectDriverState from '../project/driver/useProjectDriverState';

function ReportMyListApp(props:PropsValue) {
  const [searchParams] = useSearchParams();
  const inhouse = searchParams.get("inhouse") ?  searchParams.get("inhouse") : "1" ;
  const [token, setToken] = useState(null)
  const [profile, setProfile] = useState(null)
  const {my_project_list, getMyProjectList} = useProjectDriverState()


  useEffect(() => {
   
    if(token){
      //GET LIST
      getMyProjectList(token, format(new Date(), "yyyy-MM-dd"), null, null, null, null,false,false,false,false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])




  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{

      setToken(e.token)
    }
  }

  const profileChangeHandler = (e) => {

    setProfile(e)
  }
  return(
    <div id="home">
      <PageApp current_page="report" noauth={false} content={<ReportMyList  inhouse={inhouse}  my_project_list={my_project_list} profile={profile} token={token}/>}  profileChangeHandler={profileChangeHandler} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ReportMyListApp

