
import 'whatwg-fetch'
import config from 'react-global-configuration';

import Action from '../../action/Action';
const CompanyAction = {


  getMyDetail(token,   callback, errorHandler){
    Action.fetchAuthorizedJson( config.get("API_PATH") +  "/companies/me" , token, "GET", {}, null,  callback, errorHandler)
  },

  getPublicDetail(token, id,   callback, errorHandler){
    Action.fetchAuthorizedJson( config.get("API_PATH") +  "/companies/" + id , token,  "GET", {}, null,  callback, errorHandler)
  },


  update( token, item,  callback, errorHandler){
    const method = "PATCH" 
    const url =  config.get("API_PATH") + "/companies/"
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
  },

 
}

export default CompanyAction
