import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import { format, parseISO } from 'date-fns' 
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';

import { SearchBox } from '../../stories/components/searchbox/SearchBox';
import { DetailWindow } from '../../stories/components/detail_window/DetailWindow';
import { LoadingWindow } from '../../stories/components/loading_window/LoadingWindow';


import ReportObject from './ReportObject';
import Report from "./Report"
import Utile from '../../utiles/Utile';


function ReportList(props:PropsValue) {


  const [list_uncheck, setListUnset] = useState(null)
  const [list_pending, setListPending] = useState(null)
  const [list_checked, setListChecked] = useState(null)
  const [is_search, setIsSearch] = useState(false)
  const [is_partner, setIsPartner] = useState(false)
  const [loading, setLoading] = useState(null)
  const [is_editable, setIsEditable] = useState(null)
  const url = window.URL || window.webkitURL;
  const [detail, setDetail] = useState(null)



  const loadingCloseHanlder = ()=>{
    setLoading(null)
  }
  const check = (item) => {
    setLoading(<LoadingWindow closeHandler={loadingCloseHanlder} ></LoadingWindow>)
    props.checkHandler(item)
  }

  const pending = (item) => {
    var  new_item = new ReportObject(item)
    new_item = new_item.set("is_return",2)
    setLoading(<LoadingWindow closeHandler={loadingCloseHanlder} ></LoadingWindow>)
    props.pendingHandler(new_item)
  }

  useEffect(() => {

    if(props.unchchecked_list && props.checked_list){
      setLoading(null)
      const _un_checked_list = props.unchchecked_list.map(item => {       
        return createTd(item)
      })
      _un_checked_list.unshift(<tr className="check_tr"><td colSpan="16">確認待ち <span>{props.unchchecked_list.length} 件</span></td></tr>)
      if(_un_checked_list.length === 1){
        _un_checked_list.push(<tr ><td colSpan="16">該当するレポートがありません。</td></tr>)
      }
      setListUnset(_un_checked_list)

      const _checked_list = props.checked_list.map(item => {
        return createTd(item, "checked_area")
      })
      
      _checked_list.unshift(<tr className="check_tr"><td colSpan="16">確認済み </td></tr>)      
      
      if(_checked_list.length === 1){
        _checked_list.push(<tr ><td colSpan="16">該当するレポートがありません。</td></tr>)
      }
      setListChecked(_checked_list)


      const _pendeing_list = props.pending_list ? props.pending_list.map(item => {
        return createTd(item)
      }) : []
      _pendeing_list.unshift(<tr className="check_tr"><td colSpan="16">保留 <span>{_pendeing_list.length} 件</span></td></tr>)
      if(_pendeing_list.length === 1){
        _pendeing_list.push(<tr ><td colSpan="16">該当するレポートがありません。</td></tr>)
      }
      setListPending( _pendeing_list)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.unchchecked_list, props.pending_list, props.updated_at, props.checked_list])

  

  useEffect(() => {
  
    setIsPartner(props.profile && props.profile.user_type === "partner" ? true : false)
    setIsEditable(props.profile && props.profile.user_type !== "partner" ? true : false)
  },[props.profile])
  


  const btnClickHandler = (e ,type) =>{
    props.search(e, type ? Number(type) : null)
    setIsSearch(true)
  }

  const clearHandler = () =>{
    window.location.href = "/report"
  }
  

  const updateHandler = (e,command) => {
    setLoading(<LoadingWindow closeHandler={loadingCloseHanlder} ></LoadingWindow>)
    props.updateHandler(e,command)
  }

  const closeHandler = () => {
    setDetail(null)
  }
  const displayDetail = (item) => {
  
    var _report =  new ReportObject(item)
    _report = _report.set("worked_date", parseISO(item.worked_date) )
    _report = _report.set("work_start_at", parseISO(item.work_start_at) )
    _report = _report.set("work_end_at", parseISO(item.work_end_at) )

    setDetail(<DetailWindow report={_report} url={url} token={props.token} closeHandler={closeHandler} profile={props.profile} updateHandler={updateHandler} pendingHandler={pending} unCheckHandler={props.unCheckHandler} checkHandler={props.checkHandler} />)
  }

  const hasImage = (receipts) => {
    const withimg = receipts.filter(item => item.path !== null)

    if(withimg.length > 0) return true 
    return false
  }

  const createTd = (item, trclass) => {
    var total_receipts_value = 0
    if(item.fee_information){
      total_receipts_value += item.fee_information.highway_fee
      total_receipts_value += item.fee_information.other_fee
      total_receipts_value += item.fee_information.parking_fee
    }
    item.receipts.map(item => {
      total_receipts_value += item.price
      return null
    })
   
    const _item = new  Report(item.project)
    const report = new ReportObject(item)
    
    return(
      <tr key={"item_" + item.id} className={trclass + " report_daily"} >
        
        {item.checked_at && item.is_return !== 2 ? <td className="no_hover"><span  className="status_change">確認済み</span></td>
         : item.is_return !== 2  && is_editable ?
         <td> <span>確認待ち</span><span  className="status_change blue" onClick={() => check(item)}>確認済み</span>&nbsp;|&nbsp;<span onClick={() => pending(item)} className ="status_change yellow">保留</span></td>
         : 
         <td> <span >保留</span></td>}
        <td className="hover" onClick={() => displayDetail(item)}>{item && item.project ? Utile.getLabelByValue(config.get("PROJECT_TYPE"), item.project.project_type): null}</td>
        <td className="hover" onClick={() => displayDetail(item)}>{item && item.worked_date ? format(parseISO(item.worked_date), 'M月d日') : null}</td>
        <td className="hover" onClick={() => displayDetail(item)}>{item && item.project  && item.project.project_type === 1 ? item.project.name : item ? item.detail : null }</td>
        <td className="hover" onClick={() => displayDetail(item)}>{item && item.driver ? item.driver.partner.name : null}</td>
        <td className="hover" onClick={() => displayDetail(item)}>{Number(item.driver.company_driver) === 1 ? "*" : null}</td>
        <td className="hover" onClick={() => displayDetail(item)}>{item && item.driver_name ? item.driver_name : item.driver ? item.driver.name : null}</td>
        <td className="hover" onClick={() => displayDetail(item)}>{item && item.work_start_at ? format(parseISO(item.work_start_at), 'HH:mm') : null}</td>
        <td className="hover" onClick={() => displayDetail(item)}>{item && item.work_end_at ? format(parseISO(item.work_end_at), 'HH:mm') : null}</td>
        <td className="hover" onClick={() => displayDetail(item)}>{report.getDeliveryNumber()}件</td>
        <td className="hover" onClick={() => displayDetail(item)}>{total_receipts_value ? total_receipts_value.toLocaleString() : 0}円</td>
        
  
        <td className="no_hover" onClick={() => displayDetail(item)}>
          {item && hasImage(item.receipts) ? <i className="fa-solid fa-camera"></i>: null} &nbsp;
          {item && item.note && item.note !== "" ? <i className="fa-solid fa-pen"></i>: null}
        </td>
        
       
        
        <td className="hover" onClick={() => displayDetail(item)}>{report  ? report.distance + "km" : null}</td>
        <td className="hover" onClick={() => displayDetail(item)}>{report  ? _item.getOverTimeHourString(_item.getOverTimeHour(parseISO(report.work_start_at), parseISO(report.work_end_at), report.fee_information ? report.fee_information.over_time : 0)) : null}</td>
        


        <td className="hover" onClick={() => displayDetail(item)}>{item && item.fee ? item.fee.toLocaleString(): null }</td>
        <td className="hover" onClick={() => displayDetail(item)}>{report ? report.getRealFee().toLocaleString(): null }</td>

      </tr>
    )
  }
  
  const moveToList = (type ) => {
    if(type === "regular" && props.spot === "spot"){
      window.location.href = "/report"
    }else if (type === "spot"  && props.spot !== "spot"){
      window.location.href = "/report/spot"
    }
  }


  const isTarget = ( target) => {
    if(is_search){
      if(!props.search_item ) return true 
      if(!props.search_item.project_type)return true
      if(target === "regular"){
        if(Number(props.search_item.project_type) === 1 ) return true
        return false
      }else{
        if(Number(props.search_item.project_type) === 2 ) return true
        return false
      }
      
    }else{
      if(target === "regular"){
        if(props.spot === "spot" ) return false
        return true
      }else{
        if(props.spot !== "spot" ) return false
        return true
      }
    }
    
      
  }

  const goToPrev = () => {
    props.goToPrev()
  }

  const goToNext = () => {
    props.goToNext()
  }

  return(
    <div className="inner full">
        {detail ||  loading?  <div id="overlay" className="overlay" onClick={closeHandler}></div> : null}
        
			  <div className="content_wrap">
					  <div className="page_title_wrap">
								<h2 className="page_title">業務日報確認({props.spot === "spot" ? "スポット" : "定期"})</h2>
                <Breadcrumbs pages={[new LinkProps("業務日報確認","#")]} />
						</div>
            
            
            {loading}
            <SearchBox  profile={props.profile} token={props.token}  is_partner={ is_partner} btnClickHandler={btnClickHandler} clearHandler={clearHandler} />
            
            
           
            <div className="tab-box">
              <div className={ isTarget("regular") ? "tab-item selected" : "tab-item" } onClick={() => moveToList("regular")} >定期</div>
              <div className={ isTarget("spot")  ? "tab-item selected" : "tab-item" }  onClick={() => moveToList("spot")}>スポット</div>
            </div>
             
            <table className="datatable dayreport_tbl">
							
							<thead>
                <tr>
									<th>ステータス</th>
                  <th>区分</th>
                  <th>日付	</th>
                  <th>案件</th>
                  <th>依頼先</th>
                  <th>自社</th>
									<th>ドライバー名</th>
                  <th>開始時間	</th>
                  <th>終了時間	</th>
                  <th>件数</th>
									<th>立替金</th>
                  <th>写真＆メモ</th>
                  <th>走行距離	</th>
                  <th>残業超過	</th>
									
                  
									<th>運賃1</th>
                  <th>運賃2</th>
									
                 
                  
    
								</tr>
							</thead>
							<tbody>
              {list_uncheck}
              {list_pending}
              {list_checked}
							</tbody>
							<tfoot>
								
							</tfoot>
						</table>
            <div className="paging-footer">
            {
                     props.current_page && props.current_page !== 1 ? <div className="prev" onClick={goToPrev}> ＜ 前の30件を表示 {props.page }</div> : null
              }

             {
                      props.checked_list && props.checked_list.length === 30 ? <div className="next" onClick={goToNext}> 次の30件を表示 ＞</div> : null
              }
              </div>
				</div>
        {detail}
		</div>

  )
}

export default ReportList

/**
 * <td onClick={() => displayDetail(item)}>{item && item.project && item.project.project_type === 1 && item.project.client ? item.project.client.name : null}</td>
 */
