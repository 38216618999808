import { useState } from 'react';
import UserAction from './UserAction';


function useUserState() {

  
  const [error, setError] = useState(null)
  const [created, setCreated] = useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState(null)
  const [total_number, setTotalNumber] = useState(null)
  const [list_updated_at, setListUpdatedAt] = useState(null)

  const listLoadeddHandler = (data) => {
    setList(data.data)
    setListUpdatedAt(new Date())

    setTotalNumber(data.headers.get("total-number"))
  }
  const loadedHandler = (data) => {
    setItem(data.data)
  }
  const createdHandler = (data) => {
    setItem(data.data)
    setCreated(data.data)
  }


  const errorHandler = (data) => {
    setError(data)
  }

  const hasCookie = () => {
    return UserAction.hasCookie()
  }

  const saveOnCookie = (item) => {
    console.log(item)
    UserAction.saveOnCookie(item)
  }

  const loadFromCookie = (item) => {
    UserAction.loadFromCookie(item, loadedHandler, errorHandler)
  }

  const removeCookie = () => {
    UserAction.removeCookie()
  }
  
  const getList = (token, page, item) => {
    const offset = (page - 1)  * 10 
    UserAction.getList(token, item, offset, listLoadeddHandler, errorHandler)
  }


  const create = (token, item) => {
    UserAction.create(token, item, createdHandler, errorHandler)
  }

  const getDetail = (token, id) => {
    UserAction.getDetail(token, id, loadedHandler, errorHandler)
  }

  const deleteItem = (token, id) => {
    UserAction.delete(token, id, createdHandler, errorHandler)
  }



  return { user:item, user_list:list, list_updated_at:list_updated_at, user_error:error, user_deleted:created,  user_created:created, user_total_number:total_number, getUserList:getList, getUserDetail:getDetail, saveOnCookie:saveOnCookie, loadFromCookie:loadFromCookie, hasUserCookie:hasCookie, removeUserCookie:removeCookie, userCreate:create, deleteUser:deleteItem};
}

export default useUserState