import React, { useEffect, useState } from 'react'
import Utile from '../../../utiles/Utile';
import { format, parseISO,addDays,isSameDay } from 'date-fns';

import useReportState from '../../report/useReportState';

function BodyCommition(props:PropsValue) {
  
  const [list, setList] = useState(null)

  const [repot_list , setReportList] = useState([])
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())
  const {getHighwayCost,getParkingCost,getOtherCost} = useReportState()
 
  useEffect(() => {
    setReportList(props.repot_list)
    setRepoListUpdatedAt(new Date())
 
  },[ props.repot_list, props.repo_list_updated_at])

  useEffect(() => {
    
    if(repot_list ){
       
        var distance_total = 0
     
        var highway_total = 0
        var parking_total = 0
        var other_total = 0
        var fee_total = 0
        var fee_real_total = 0

        const _list = new Array(props.days + 1).fill(0).map((d,index) => {

          const target_day = addDays(props.start_date, index)
          const item = repot_list.find(item => isSameDay(parseISO(item.worked_date),target_day ))
         
          var highway_cost = 0
          var parking_cost = 0
          var other_cost = 0
          var fee = 0
          var fee_real = 0 
  
          
          if(item){
      
            
           // over_time_hour = item.over_time_hour ? item.over_time_hour  : monthly_report.getOverTimeHour(parseISO(item.work_start_at), parseISO(item.work_end_at))
            
            fee_real = item.getRealFee()
      
            fee = item.fee
            highway_cost = getHighwayCost(item.receipts, item.fee_information ? item.fee_information.highway_fee: 0)
            parking_cost = getParkingCost(item.receipts, item.fee_information ?item.fee_information.parking_fee: 0)
            other_cost = getOtherCost(item.receipts, item.fee_information ? item.fee_information.other_fee: 0)
            
            distance_total += item.distance
          
            highway_total += (highway_cost )
            parking_total += parking_cost
            other_total += other_cost 
            fee_total += fee
            fee_real_total += Number(fee_real ? fee_real : 0)
          }
     
          return(
            <tr className={item ? "link" : null} >
              {props.is_public ||!props.has_edit_right  ? null : <td>{item ? <input type="checkbox" checked={props.selected_list.find( s => (item && s === item.id)) ? true : false} onClick={() => props.checkHandler(item.id)}/> : null}</td>}
              <td onClick={() => item? props.changeHandler(item.id) :null}>{format(target_day, "dd")}</td>
              <td onClick={() => item? props.changeHandler(item.id) :null}>{Utile.getWeekString(target_day)}</td>
              
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{format(parseISO(item.work_start_at), "HH:mm")}～{format(parseISO(item.work_end_at), "HH:mm")}</td>) : <td></td> }
              
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.area}</td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.distance} <span className="km">km</span></td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.requested_unit} <span className="km">便</span></td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.delivery_unit} <span className="km">個</span></td>) : <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.normal_unit} <span className="km">個</span></td>): <td></td>}
              
             
              {!props.is_public ? 
                item ? (<td onClick={() => item? props.changeHandler(item.id) :null}><span className="yen_mark">&yen;</span>{fee.toLocaleString()}</td>): <td></td>
               : null}
               {!props.is_public ? 
                item ? (<td onClick={() => item? props.changeHandler(item.id) :null}><span className="yen_mark">&yen;</span>{fee_real ? fee_real.toLocaleString() : null}</td>): <td></td>
               : null}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{highway_cost.toLocaleString()}/{parking_cost.toLocaleString()}/{other_cost.toLocaleString()}</td>): <td></td>}
          
            </tr>
          )
        })
      _list.push(<tr>
        <td colSpan={props.is_public ? 4 : !props.has_edit_right ?4:5} className="report_subtotal">合計</td>
        <td>{distance_total.toLocaleString()}<span className="km">km</span></td>
        <td colSpan={3}></td>
        {!props.is_public ? <td><span className="yen_mark">&yen;</span>{fee_total.toLocaleString()}</td> : null}
        {!props.is_public ? <td><span className="yen_mark">&yen;</span>{fee_real_total.toLocaleString()}</td> : null}
        <td>{highway_total.toLocaleString()}/{parking_total.toLocaleString()}/{other_total.toLocaleString()}</td>
        
        </tr>)
      setList(_list)
      
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ repo_list_updated_at,props.selected_at])




  return(
    <table>
      <tbody>
      <tr>
      {props.is_public || !props.has_edit_right? null : <th><input type="checkbox" onChange={props.checkAll}/></th>}
        <th>日</th>
        <th>曜</th>
        <th>時間</th>
        <th>エリア</th>
        <th>距離</th>
        <th>便数</th>
        <th>持出個数</th>
        <th>配完個数</th>
        {!props.is_public ? <th>報告運賃</th> : null}
        {!props.is_public ? <th>概算運賃</th> : null}
        <th>高速/駐車/その他</th>
      </tr>
      {list}
      </tbody>
    </table>
    
  )
}

export default BodyCommition

/**
 *   {item ? (<td><input type="time" className="report_time" value={format(parseISO(item.work_start_at), "HH:mm")} name="work_start_at" onChange={(e) => props.changeTimeHandler(e, item.id)} />～<input type="time" value={format(parseISO(item.work_end_at), "HH:mm")} name="work_end_at" onChange={(e) => props.changeTimeHandler(e, item.id)} className="report_time" /></td>) : <td></td> }
              {item ? (<td><input type="text" className="report_erea" value={item.area} name="area" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
              {item ? (  <td><input type="number"  className="report_distance" value={item.distance}  name="distance" onChange={(e) => props.changeHandler(e, item.id)} /><span className="km">km</span></td>): <td></td>}
              {item ? (  <td><input type="number" className="" value={over_time_hour}  name="over_time_hour" onChange={(e) => props.changeHandler(e, item.id)}  /><span className="yen_mark">分</span></td>): <td></td>}
              {item ? (  <td><input type="number" className="" value={over_distance}  name="over_distance"  /><span className="km">km</span></td>) : <td></td>}
              {item ? (  <td><span className="yen_mark">&yen;</span><input type="number" className="" value={item.fee ? Number(item.fee) : 0}   name="fee" onChange={(e) => props.changeHandler(e, item.id)} /></td>) : <td></td>}
              {item ? (  <td><span className="yen_mark">&yen;</span><input type="number"  name="tatekae" onChange={(e) => props.changeHandler(e, item.id)}  className="report_reimbursement" value={tatekae}  /></td>): <td></td>}
 */