import React, { useEffect, useState } from 'react'

import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import { Pageing } from '../../stories/components/pageing/Pageing';
import { SearchBoxProject } from '../../stories/components/searchbox/SearchBoxProject';
import ProjectDriver from './driver/ProjectDriver';

import ProjectDetailOverlay from './ProjectDetailOverlay';
function ProjectList(props:PropsValue) {

  const [list, setList] = useState(null)
  const [total_number, SetTotalNumber] = useState(1)
  const [detail, setDetail] = useState(null)

  useEffect(() => {
    if(props.projects){
      setList(
        props.projects.map(item => {
          const working_drivers = item.drivers.filter(
            driver => { 
              const d = new ProjectDriver(driver)
              return d.is_working()
            }
          )
       
          return(
            <tr key={"item_" + item.id}>
              <td>{item.id}</td>
      
              <td>{item.client.name}</td>
              <td>{item.name}</td>
              <td >
                <div className="buttons" >
                  
                  { working_drivers.length  }人
               
                </div>
              </td>
              <td>
                <div className="buttons">
                  <a href={"/project/" + item.id + "/driver/new?new=1"} className="icon_button"><i className="fa-solid fa-pen"></i></a>
                </div>
              </td>
              <td>
                <div className="buttons">
                  <div className="textbutton" onClick={() => displayDetailInfo(item)}>詳細確認</div>
                </div>
              </td>
             
            </tr>
          )
        })
      )
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.projects])


  useEffect(() => {

    SetTotalNumber(props.total_number)
  },[props.total_number])
  
  const displayDetailInfo = (item) => {

    setDetail(<ProjectDetailOverlay profile={props.profile} token={props.token} project={item} is_edit={false} closeHandler={closeHandler} />)
  }
  
  const btnClickHandler = (e) =>{
    props.search(e)
  }


  const clearHandler = () =>{
    window.location.href = "/project"
  }

  const closeHandler = () => {
    setDetail(null)
  }


  return(
    <div className="inner">
         {detail ?  <div id="overlay" className="overlay" onClick={closeHandler} ></div> : null}
			  <div className="content_wrap">
					  <div className="page_title_wrap">
								<h2 className="page_title">案件一覧</h2>
                <Breadcrumbs pages={[new LinkProps("案件一覧","#")]} />
						</div>
            <a href="/project/new?new=1" className="button red">案件新規作成</a>
            <SearchBoxProject  token={props.token} btnClickHandler={btnClickHandler} clearHandler={clearHandler} />
            <table className="datatable">
							
							<thead>
                <tr>
									<th>No.</th>
					
									<th>クライアント名	</th>
									<th>案件名</th>
                  <th>担当ドライバー数</th>
                  <th>担当ドライバー追加</th>
									<th>詳細確認</th>
     
								
									
								</tr>
							</thead>
							<tbody>
              {list}
							</tbody>
							<tfoot>
								<tr>
									<td colspan="99">
                 
                    <Pageing total_number={total_number} current_page={props.current_page} limit={props.search_limit} />
									</td>
								</tr>
							</tfoot>
						</table>
				</div>
        {detail}
		</div>

  )
}

export default ProjectList


/**
 * <a href={"/project/" + item.id} className="icon_button"><i className="fa-solid fa-pen"></i></a>
                <a href={"/project/" + item.id + "/delete"} className="icon_button"><i className="fa-solid fa-trash"></i></a>
                 <td><a href={"/project/" + item.id} className="icon_button"><i className="fa-solid fa-pen"></i></a></td>
 */