import React, {useState, useEffect  } from 'react'

import { useSearchParams } from "react-router-dom";

import { PageApp } from '../../stories/PageApp'
import PartnerList from "./PartnerList"
import usePartnerState from './usePartnerState';
import useProjectState from '../project/useProjectState';
import useProjectDriverState from '../project/driver/useProjectDriverState';
import { LoadingWindow } from '../../stories/components/loading_window/LoadingWindow';

function PartnerListApp(props:PropsValue) {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ?  searchParams.get("page") : 1 ;
  const [loading, setLoading] = useState(null)
  const [token, setToken] = useState(null)

  const [total_number, setTotalNumber] = useState(1)
  const {partner_list, partner_updated_at,partner_total_number, partner_search_limit, getPartnerList} = usePartnerState()
  const [profile, setProfile] = useState(null)
  const {my_project_list,  getListByPartners} = useProjectDriverState()
  const {project_list_updated_at,  project_list, getProjectList} = useProjectState()

  useEffect(() => {
  
    setLoading(<LoadingWindow closeHandler={loadingCloseHanlder} ></LoadingWindow>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])



  useEffect(() => {
    if(partner_list){
     const user_ids = partner_list.map(item => item.user_id)
     getListByPartners(token, user_ids)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[partner_updated_at])
 
 
   useEffect(() => {
     if(my_project_list){
       getProjectList(token,1,null,{ids:my_project_list.map(item => item.project_id)})
     }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[my_project_list])


  useEffect(() => {
    if(partner_total_number > 1){
      setTotalNumber(partner_total_number)
    }else{
      setTotalNumber(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner_total_number])


  useEffect(() => {
    if(partner_list){
      setLoading(null)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_list_updated_at])

  useEffect(() => {

    if(token){
      //GET LIST
      getPartnerList(token,page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  const loadingCloseHanlder = ()=>{
    setLoading(null)
  }

  const searchHandler = (e) => {
    setLoading(<LoadingWindow closeHandler={loadingCloseHanlder} ></LoadingWindow>)
    getPartnerList(token,1, e)
  }

  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  return(
    <div id="home">
      {loading}
      <PageApp current_page="partner" content={<PartnerList  token={token} project_driver_list={my_project_list} profile={profile} project_list={project_list}  partners={partner_list} search_limit={partner_search_limit} total_number={total_number} current_page={page} search={searchHandler}  />} authChangeHandler={authChangeHandler} profileChangeHandler={profileChangeHandler}/>
    </div>
     
  )
}

export default PartnerListApp

