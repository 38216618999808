import React, {ReactNode,useEffect} from 'react';


import './pageapp.css';
import { Header } from './components/header/Header';
import { SideMenu } from './components/sidemenu/SideMenu';
import useAuthState from './auth/useAuthState';
import useProfileState from './profile/useProfileState';



interface PageAppProps {

   /**
   * title
   */
   title? : string

  /**
   * content
   */
  content:ReactNode


  /**
   * current page
   */
  current_page?: string

  noauth ? :boolean
  
  /**
   * auth Change Handler/ token or error
   */
  authChangeHandler: (e:any) => void

  profileChangeHandler: (e:any) => void
   
}

/**
 * Primary UI component for user interaction
 */
export const PageApp = ({
  title,
  content,
  current_page,
  noauth,
  authChangeHandler,
  profileChangeHandler,
  ...props
}: PageAppProps) => {



  const {token, auth_error, getToken} = useAuthState()
  const {profile,  getProfile} = useProfileState()
  

  useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(token){
      authChangeHandler({token:token})
      getProfile(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])


  useEffect(() => {
    if(auth_error){
      authChangeHandler({error:auth_error})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[auth_error])

  useEffect(() => {
  
    if(profile && profileChangeHandler){
      profileChangeHandler(profile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile])

  return (
    <div>
      <Header profile={profile}  noauth={noauth}/>
      <div className="main_row row">
        <SideMenu current_page={current_page} user_type={profile ? profile.user_type : undefined}/>
        <main>{content}</main>
      </div>
    </div>
  );
};

