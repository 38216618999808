import React, {  } from 'react'

function HeaderSTSL(props:PropsValue) {

  /**
   * year
   * mnth
   * project
   * project_driver
   * report list
   */

  
  return(
    <div className="slst-header">
       <h3> <input type="text" className="in01" value={props.year} />年<input type="text" className="in01" value={props.month} />月 業務日報</h3>
       <div className="bottom">
        <div className="y">屋号　<span>{props ? props.driver.partner.name : null}</span></div>
        <div className="y">氏名　<span>{props ? props.driver.name : null}</span></div>
      </div>
		</div>
    
  )
}

export default HeaderSTSL

