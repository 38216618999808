import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import Select from 'react-select';
import format from 'date-fns/format';
import { isDate } from 'date-fns';
import Utile from '../../../utiles/Utile';
import ProjectDriverOverlay from './ProjectDriverOverlay';
import { ParnterSearchBox } from '../../../stories/components/partner_search/ParnterSearchBox';
function ProjectDriverDetailItem(props:PropsValue) {
  const default_styles={
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "#f5f5f5",
      padding:".5rem .2rem",
      borderRadius:"10px",
    }),
  }
  const [drivers, setDrivers] = useState(null)
  const [message, setMessage] = useState(null)
  const [driver_message, setDriverMessage] = useState(null)
  const [project, setProject] = useState(props.projectObject)

  const [overlay, setOverLay] = useState(null)

  useEffect(() => {
    setProject(props.projectObject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.projectObject])


  useEffect(() => {
    if(props.drivers){
      const _drivers =props.drivers
      _drivers.push({value:"new", label:"----新規登録----"})
      setDrivers(_drivers)
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.drivers])

  

  const copy = () => {
    navigator.clipboard.writeText(props.report_url).then(  () => {}, () => {});
    setMessage(<span className="copyied">コピーしました。</span>)
  }

  const changeDriverHandler = (e) => {
    if(e.value === "new"){
      setOverLay(<ProjectDriverOverlay partner_id={props.partner_def} token={props.token} createdHandler={createdNewDriverHandler} closeHandler={closeOverlayHandler}/>)
    }else{
      console.log(e)
      const is_exsis = project.drivers.find(driver  => Number(driver.driver_id)  ===  Number(e.value))
     if(is_exsis){
      setDriverMessage(<div className="message">すでに追加されているドライバーです。</div>)
     }else{
      props.changeDriverHandler(e)
     }

      
    }
   
  }

  const closeOverlayHandler = () => {
    setOverLay(null)
  }

  const createdNewDriverHandler = (driver) => {
    setOverLay(null)
    props.createdNewDriverHandler(driver)
  }



  return(
    <div>
      {overlay?  <div id="overlay" className="overlay" onClick={closeOverlayHandler}></div> : null}
    <table class="input_table col12 login_tbl">
		  <tbody>
      {
            project ? 
			  <tr>
				  <th class="tl">クライアント名 </th>
					  <td>{project ? project.client.name: null}</td>
					</tr>
          :null}
			{
        project ? 
          <tr>
					<th class="tl">案件名</th>
					<td>{project ? project.name : null }</td>
					</tr>
           :null}
      {
            project ? 
          <tr>
					  <th class="tl">区分</th>
						<td>{project && project.project_type ? Utile.getLabelByValue(config.get("PROJECT_TYPE"),Number(project.project_type)):null}</td>
					</tr>
           :null}
			{
        project ? 
          <tr>
				    <th class="tl">種類</th>
						<td>{project && project.category ? Utile.getLabelByValue(config.get("PROJECT_CATEGORY"),Number(project.category)):null}</td>
					</tr>
           :null}
					<tr>
					  <th class="tl">依頼先会社名 *</th>
              {
                  props.item && props.item.id && props.item.driver? 
                  <td>{props.item.driver.partner.name }</td>
                  :
                  <td>
                    <ParnterSearchBox changeHandler={props.changePartnerHandler} token={props.token} />
                  </td>
              }
					</tr>
					
          <tr>
					  <th class="tl">担当ドライバー *</th>
              {
                props.item && props.item.id && props.item.driver? 
                <td>{props.item.driver.name }</td>
                :
                <td>
                  <Select
                    placeholder= "選択してください"
                    options={drivers}
                    loadOptions={props.loadDriverOptions}
                    noOptionsMessage={()=>"該当するドライバーが見つかりません"}
                    onChange={changeDriverHandler}
                    styles={default_styles}
                    value={props.driver_def}
                  />{driver_message}
								</td>            
              }
          </tr>
          {props.report_url ? 
            <tr>
						  <th class="tl">日報URL </th>
                  <td>
                    <a href={props.report_url}>ドライバー入力フォーム <i className="fa-solid fa-arrow-right"></i> </a>&nbsp; &nbsp; <span className="link" onClick={copy}> <i className="fa-regular fa-copy"></i></span>{message}
                  </td>
							</tr>
          : null}
					<tr>
						  <th class="tl">案件開始日 *</th>
              <td>
              {
                props.is_edit ? 
                <input type="date" class="col9" placeholder="2023年6月1日" name="start_date" value={props.item && props.item.start_date ? format(isDate(props.item.start_date)  ? props.item.start_date : new Date(props.item.start_date) , "yyyy-MM-dd") : undefined} min={"2000-01-01"} onChange={props.changeHandler} />
                :
                format(isDate(props.item.start_date)  ? props.item.start_date : new Date(props.item.start_date) , "yyyy年M月d日")
              }
							</td>
					</tr> 
          <tr>
						<th class="tl">案件終了日 </th>
						<td>
              {  
              props.is_edit ? 
                <input type="date" class="col9" placeholder="2023年6月1日" name="end_date" value={props.item && props.item.end_date ? format(isDate(props.item.end_date)  ? props.item.end_date : new Date(props.item.end_date), "yyyy-MM-dd") : undefined}   onChange={props.changeHandler} />
              :
              props.item.end_date ? format(isDate(props.item.end_date)  ? props.item.end_date : new Date(props.item.end_date), "yyyy年M月d日") : null
              }
            </td>
				  </tr>


          {project &&  project.is_unit_price ? 

          <tr>
					  <th class="tl">支払い単価 *</th>
						<td>
            {  
              props.is_edit ? 
              <input autocomplete="off" type="number" onWheel={(e) => e.target.blur()}  class="col9" placeholder="10000" name="unit_price" value={props.item && props.item.fee_information && props.item.fee_information.unit_price ? props.item.fee_information.unit_price : undefined}  onChange={props.changeFeeHandler} />
              :
              props.item.fee_information.unit_price
            }  
            　円</td>
					</tr>
          :null}
          
          {project &&  Number(project.category) === 4 ?
          
          <tr>
            <th class="tl">ヤマト宅配通常支払い単価 </th>
            <td>
            {  
              props.is_edit ? 
              <input autocomplete="off" type="number"   onWheel={(e) => e.target.blur()} class="col9" placeholder="10000" name="unit_yamato_normal" value={props.item && props.item.fee_information ? props.item.fee_information.unit_yamato_normal : undefined}  onChange={props.changeFeeHandler} />
                :props.item.fee_information.unit_yamato_normal
            }
              　円</td>
          </tr>
            :null 
          }
          {
            project && project.category === 4 ?
            <tr>
              <th class="tl">ヤマト宅配ネコポス支払い単価 </th>
              <td>
              {  
              props.is_edit ? 
                <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()} class="col9" placeholder="10000" name="unit_yamato_nekopos" value={props.item  && props.item.fee_information ? props.item.fee_information.unit_yamato_nekopos : undefined}  onChange={props.changeFeeHandler} />
              :props.item.fee_information.unit_yamato_nekopos}
              　円</td>
            </tr>
              :null 
            }
            {project && project.category === 4 ?
            <tr>
              <th class="tl">ヤマト宅配DM支払い単価 </th>
              <td>
              {  
              props.is_edit ? 
                <input autocomplete="off" type="number" class="col9"  onWheel={(e) => e.target.blur()} placeholder="10000" name="unit_yamato_dm" value={props.item  && props.item.fee_information ? props.item.fee_information.unit_yamato_dm : undefined}  onChange={props.changeFeeHandler} />
                :props.item.fee_information.unit_yamato_dm}
              　円</td>
            </tr>
            :null 
            }

          {project &&  Number(project.category) === 5 ?
          
          <tr>
            <th class="tl">通常単価 </th>
            <td>
            {  
              props.is_edit ? 
              <input autocomplete="off" type="number" class="col9" onWheel={(e) => e.target.blur()} placeholder="10000" name="unit_yusei_normal" value={props.item && props.item.fee_information ? props.item.fee_information.unit_yusei_normal : undefined}  onChange={props.changeFeeHandler} />
                :props.item.fee_information.unit_yusei_normal
            }
              　円</td>
          </tr>
            :null 
          }

        
          {project &&  Number(project.category) === 5 ?
          
          <tr>
            <th class="tl">大型単価 </th>
            <td>
            {  
              props.is_edit ? 
              <input autocomplete="off" type="number" class="col9" onWheel={(e) => e.target.blur()} placeholder="10000" name="unit_yusei_big" value={props.item && props.item.fee_information ? props.item.fee_information.unit_yusei_big : undefined}  onChange={props.changeFeeHandler} />
                :props.item.fee_information.unit_yusei_big
            }
              　円</td>
          </tr>
            :null 
          }

          {project &&  Number(project.category) === 5 ?
          
          <tr>
            <th class="tl">夜間単価 </th>
            <td>
            {  
              props.is_edit ? 
              <input autocomplete="off" type="number" class="col9"  onWheel={(e) => e.target.blur()}placeholder="10000" name="unit_yusei_yakan" value={props.item && props.item.fee_information ? props.item.fee_information.unit_yusei_yakan : undefined}  onChange={props.changeFeeHandler} />
                :props.item.fee_information.unit_yusei_yakan
            }
              　円</td>
          </tr>
            :null 
          }

          {project &&  Number(project.category) === 5 ?
          
          <tr>
            <th class="tl">集荷単価 </th>
            <td>
            {  
              props.is_edit ? 
              <input autocomplete="off" type="number" class="col9" onWheel={(e) => e.target.blur()} placeholder="10000" name="unit_yusei_collect" value={props.item && props.item.fee_information ? props.item.fee_information.unit_yusei_collect : undefined}  onChange={props.changeFeeHandler} />
                :props.item.fee_information.unit_yusei_collect
            }
              　円</td>
          </tr>
            :null 
          }


            {
              project && project.overtime_fee_type !== 100 && project.working_time_type !==  3 ?
                <tr>
                  <th class="tl">支払い残業単価 </th>
                  <td>
                  {  
                    props.is_edit ? 
                    <input autocomplete="off" type="number" class="col9" onWheel={(e) => e.target.blur()}placeholder="10000" name="over_time_unit_price" value={props.item && props.item.fee_information ? props.item.fee_information.over_time_unit_price : undefined}  onChange={props.changeFeeHandler} />
                    :props.item.fee_information.over_time_unit_price}
                    　円/{project ? project.getOverTimeUnitTime() : null}分
                    </td>
                  </tr>
              :null 
            }

            {
             project && project.distance_type !== 1 ?
            <tr>
              <th class="tl">支払い超過距離単価 </th>
              <td>
              {  
                props.is_edit ? 
                <input autocomplete="off" type="number" class="col9"  onWheel={(e) => e.target.blur()}placeholder="10000" name="over_distance_unit_price" value={props.item && props.item.fee_information ? props.item.fee_information.over_distance_unit_price : undefined}  onChange={props.changeFeeHandler} />
                :props.item.fee_information ? props.item.fee_information.over_distance_unit_price : null}
                　円/km</td>
            </tr>
            :null 
            }

             {
             project && project.distance_type !== 7 ?
            <tr>
              <th class="tl">歩合超過個数 </th>
              <td>
              {  
                props.is_edit ? 
                <input autocomplete="off" type="number" class="col9"  onWheel={(e) => e.target.blur()} placeholder="100" name="over_point_number" value={props.item && props.item.fee_information ? props.item.fee_information.over_point_number : undefined}  onChange={props.changeFeeHandler} />
                :props.item.fee_information ? props.item.fee_information.over_point_number : null}
                個</td>
            </tr>
            :null 
            }


            {
             project && project.distance_type !== 7 ?
            <tr>
              <th class="tl">歩合超過単価 </th>
              <td>
              {  
                props.is_edit ? 
                <input autocomplete="off" type="number" class="col9"  onWheel={(e) => e.target.blur()} placeholder="100" name="over_point_unit_price" value={props.item && props.item.fee_information ? props.item.fee_information.over_point_unit_price : undefined}  onChange={props.changeFeeHandler} />
                :props.item.fee_information ? props.item.fee_information.over_point_unit_price : null}
                　円/個</td>
            </tr>
            :null 
            }
            
            <tr>
              <th class="tl">日報チェック *</th>
              
              {  
              props.is_edit ? 
              <td>
                <label class="check"><input type="checkbox" name="monday" checked={props.item && Number(props.item.monday) === 1} value={props.item &&  Number(props.item.monday) === 1 ? 0 : 1}  onChange={props.changeHandler} /><span></span><b>月</b></label>
                <label class="check"><input type="checkbox" name="thuesday" checked={props.item && Number(props.item.thuesday) === 1} value={props.item &&  Number(props.item.thuesday) === 1 ? 0 : 1} onChange={props.changeHandler} /><span></span><b>火</b></label>
                <label class="check"><input type="checkbox" name="wednesday" checked={props.item && Number(props.item.wednesday) === 1} value={props.item &&  Number(props.item.wednesday) === 1 ? 0 : 1}  onChange={props.changeHandler}/><span></span><b>水</b></label>
                <label class="check"><input type="checkbox" name="thursday" checked={props.item && Number(props.item.thursday) === 1} value={props.item &&  Number(props.item.thursday) === 1 ? 0 : 1}  onChange={props.changeHandler} /><span></span><b>木</b></label>
                <label class="check"><input type="checkbox" name="friday" checked={props.item && Number(props.item.friday) === 1} value={props.item &&  Number(props.item.friday) === 1 ? 0 : 1}  onChange={props.changeHandler} /><span></span><b>金</b></label>
                <label class="check"><input type="checkbox" name="saturday" checked={props.item && Number(props.item.saturday) === 1} value={props.item &&  Number(props.item.saturday) === 1 ? 0 : 1} onChange={props.changeHandler}  /><span></span><b>土</b></label>
                <label class="check"><input type="checkbox" name="sunday" checked={props.item && Number(props.item.sunday) === 1} value={props.item &&  Number(props.item.sunday) === 1 ? 0 : 1}  onChange={props.changeHandler} /><span></span><b>日</b></label>
                </td>
              :
                <td>
                  <span>{Number(props.item.monday) === 1 ? "月　" : null} </span>
                  <span>{Number(props.item.thuesday) === 1 ? "火　" : null} </span>
                  <span>{Number(props.item.wednesday) === 1 ? "水　" : null }</span>
                  <span>{Number(props.item.thursday) === 1 ? "木　" : null} </span>
                  <span>{Number(props.item.friday) === 1 ? "金　" : null }</span>
                  <span>{Number(props.item.saturday) === 1 ? "土　" : null }</span>
                  <span>{Number(props.item.sunday) === 1 ? "日　" : null}</span>
                </td>
              }
             
            </tr>
            <tr>
              <th class="tl">条件</th>
              <td>
              {  
              props.is_edit ? 
                <select name="condition" value={props.item ? props.item.condition : undefined}  onChange={props.changeHandler}>
                <option value="" selected="">選択してください</option>
                  {
                    config.get("PROJECT_DRIVER_WORKING_TYPE").map(item => {
                      return (<option value={item.value}>{item.label}</option>)
                    })
                  }
                </select>
                : props.item.condition ? Utile.getLabelByValue(config.get("PROJECT_DRIVER_WORKING_TYPE"),Number(props.item.condition)) : null
              }
              </td>
            </tr>
            <tr>
              <th class="tl">備考</th>
              <td>
                {  
                props.is_edit ? 
                  <textarea type="text" class="col12" rows="5" name="note" value={props.item ? props.item.note : undefined}  onChange={props.changeHandler} />
                : props.item.note}
              </td>
            </tr>
			  </tbody>
		  </table>
      {overlay}
      </div>
  )
}

export default ProjectDriverDetailItem

