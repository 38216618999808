import React, {useEffect, useState} from 'react';
import {format,  setHours, setMinutes, isBefore, addDays, isDate, parseISO} from 'date-fns';
import Utile from '../../../utiles/Utile';
import Project from '../../../components/project/Project';
import config from 'react-global-configuration';
import ProjectDriver from '../../../components/project/driver/ProjectDriver';
import Report from '../../../components/report/Report';



import useReportState from '../../../components/report/useReportState';


//<input autocomplete="off" type="datetime-local" className="col9" placeholder="10:00"   value={item ? format(item.work_start_at,"yyyy-MM-dd hh:mm") : undefined} />

interface ReportDetailProps {
   
    project:Project,
    project_driver:ProjectDriver,
    report:Report,
    item:ReportObject,
    driver:Driver,
    is_editable:Boolean,
  /**
    * changeHandler
    */
   changeHandler:Function,
   changeDateHandler:Function,
    
}

/**
 * Primary UI component for user interaction
 */
export const ReportDetail = ({
  project,
  project_driver,
  report,
  item,
  driver,
  is_editable,
  changeHandler,
  changeDateHandler,
  ...props
}: ReportDetailProps) => { 



  const {getHighwayCost,getParkingCost,getOtherCost,getFee} = useReportState()
  const [project_driver_item, setProjectDriverItem] = useState(null)

  


  useEffect(() => {
 
   if(report && driver && report.project && report.project.drivers){
    
    const _project_driver = report.project.drivers.filter(p => p.driver_id === driver.id)
    if(_project_driver.length > 0) {
      setProjectDriverItem(_project_driver[0])
    }
   }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report, driver])



  const changeTimeStartHandler = (e) => {
    const target = e.target 
    const hour = target.value.split(":")[0]
    const min = target.value.split(":")[1]
    const start_date = setMinutes(setHours(item.work_start_at, hour),min)
    changeHandler({target:{name:"work_start_at", value:start_date}})
  }

  const changeTimeEndHandler = (e) => {
    const target = e.target 
    const hour = target.value.split(":")[0]
    const min = target.value.split(":")[1]
    var end_date = setMinutes(setHours(item.work_start_at, hour),min)
   
    if(isBefore(end_date, item.work_start_at)){
      end_date = addDays(end_date, 1)
    }
    
    changeHandler({target:{name:"work_end_at", value:end_date}})
  }

  const changeSpotHandler = (e) => {
    props.changeSpotHandler(e)
  }
  const changeFeeHandler = (e) => {
    const target = e.target 
    var target_fee = 0 
    switch(target.name) {
      case "unit_price" : target_fee = project_driver_item ? project_driver_item.fee_information.unit_price : 0
        break
      case "highway_fee" : target_fee = getHighwayCost(item.receipts)
        break
      case "parking_fee" : target_fee = getParkingCost(item.receipts)
        break
      case "other_fee" : target_fee = getOtherCost(item.receipts)
        break
      case "over_time" : target_fee = 0
        break
      case "over_distance" : target_fee = 0
        break
      default: {}
    }
    
  
    const new_value = Number(target.value ) - Number(target_fee)

    const new_fee_information = item.fee_information.set(target.name, new_value ? new_value : null)
    changeHandler({target:{name:"fee_information", value:new_fee_information}})
  }
  const changeWaitingHHandler = (e) => {
    const target = e.target
    var waiting_hour = item.waiting_hour
    if(waiting_hour === null) {
      waiting_hour = 0
    }

    const current_h = Math.floor(waiting_hour / 60)
    const current_m = waiting_hour - (current_h * 60)

    if(target.name === "waiting_hour_h"){
      waiting_hour =  Number(e.target.value) * 60  + current_m
    } 
    if(target.name === "waiting_hour_m"){
      waiting_hour =  (current_h * 60) +  Number(e.target.value)
    } 
    
    changeHandler({target:{name:"waiting_hour", value:waiting_hour}})

  }

  const getDriverNameTd = () => {
    if(is_editable){
    
      if(driver && driver.spot_driver !== 1){
        return driver.name
      }
      return <input autocomplete="off" type="text" name="driver_name" value={item && item.driver_name ?  item.driver_name : undefined}  onChange={changeHandler} />
    }else{
      if(item && item.driver_name) return null
      return item.driver_name
    }
  }

  const getDriverCompanyTd = () => {
    //定期案件
    if(project && project.project_type === 1){
      if(project_driver && project_driver.driver && project_driver.driver.partner){
        return project_driver.driver.partner.name
      }
    }else if(project){ //スポット 
      if(project_driver && project_driver.driver && project_driver.driver.partner && project_driver.driver.partner.is_self !== 1){ //他社
        return project_driver.driver.partner.name 
      }else if(project && project.user && project.user.company){
        return project.user.company.name
      }
        return null
    }
    return null
  }


  return (
    <table className="input_table col12 login_tbl">
    <tbody>

    {
         report && !report.driver_name  && (!report.spot_type && report.spot_type !== 0) ? 
        <tr>
        <th className="tl">ドライバー</th>
        <td>{project_driver && project_driver.driver ? project_driver.driver.name : driver ? driver.name : null}</td>
      </tr>
         :null
        }
        {
         item && item.id  ? 
        <tr>
        <th className="tl">送信日時</th>
        <td>{item.created_at ? format(parseISO(item.created_at), "yyyy年M月d日 HH:mm") : null}</td>
      </tr>
         :null
        }

        {getDriverCompanyTd() ?
        <tr>
          <th className="tl">依頼先</th>
          <td>{getDriverCompanyTd()}</td>
        </tr>
        :null}

{
        report && (report.spot_type || report.spot_type === 0)  ? 
        <tr>
          <th className="tl">ドライバー名</th>
          <td>{getDriverNameTd()}</td>
        </tr>
         :null
        }

    {project && project.project_type === 1 ?
      <tr>
        <th className="tl">案件</th>
        <td>{project ? project.name : null}</td>
      </tr>
       : null}
      {project ?
      <tr>
        <th className="tl">区分</th>
        <td>{project ? Utile.getLabelByValue(config.get("PROJECT_TYPE"), project.project_type) : null}</td>
      </tr>
      : null}
       
      {
       project &&  report && (report.spot_type || report.spot_type === 0) ? 
      <tr>
        <th className="tl">種類</th>
        <td>
        {is_editable ? 
          <select name="spot_type"  onChange={changeSpotHandler} >
            <option value="" selected="">選択してください</option>
            {
                config.get("PROJECT_SPOT_CATEGORY").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
              }
          </select>
        : report.spot_type}
        </td>
      </tr>
        :null
      }


{
        report && report.detail ? 
      <tr>
        <th className="tl">{report.detail}</th>
        <td>
        {is_editable ? 
        <input autocomplete="off" type="text" className="col9" placeholder="◯◯市 お弁当配送"  name="detail" value={item ? item.detail : undefined} onChange={changeHandler}/>
        :item ? item.detail : undefined}
        </td>
      </tr>
          :null
        }


      <tr>
        <th className="tl">日付</th>
        <td>
          {is_editable ? 
          <input autocomplete="off" type="date" className="w75" name="target_date" value={item && item.work_start_at && isDate(item.work_start_at) ?  format(item.work_start_at , "yyyy-MM-dd"): undefined}  onChange={changeDateHandler} />
            :item && item.work_start_at && isDate(item.work_start_at) ?  format(item.work_start_at , "yyyy年MM月dd日"): undefined}
        </td>
      </tr>
      
      
      {
        item && report && report.work_start_at ? 
        <tr>
          <th className="tl" dangerouslySetInnerHTML={{
        __html: report.work_start_at
      }}></th>
          <td>
          {is_editable ? 
            <input type="time" className="time w75" name="work_start_at" onChange={(e) => changeTimeStartHandler(e)} value={item && item.work_start_at && isDate(item.work_start_at) ? format(item.work_start_at , "HH:mm") : undefined} />
          :item && item.work_start_at && isDate(item.work_start_at) ? format(item.work_start_at , "HH:mm") : undefined }
          </td>
        </tr>
        :null
      }


      {
        item  && report && report.drive_start_at? 
      <tr>
        <th className="tl" dangerouslySetInnerHTML={{
        __html: report.drive_start_at
      }}></th>
        <td>
        {is_editable ? 
          <input type="time"  className="time w75"  name="drive_start_at" onChange={changeHandler} value={item && item.drive_start_at && isDate(item.drive_start_at) ? format(item.drive_start_at , "HH:mm") : item.drive_start_at ? item.drive_start_at : undefined} />
        :item && item.drive_start_at && isDate(item.drive_start_at) ? format(item.drive_start_at , "HH:mm") : item.drive_start_at ? item.drive_start_at : undefined}
        </td>
      </tr>
        :null
      }
      {
        report && report.area ? 
      <tr>
        <th className="tl">{report.area}</th>
        <td>
        {is_editable ? 
          <input autocomplete="off" type="text" className="col9" placeholder="◯◯市、◯◯区、など"  name="area" value={item ? item.area : undefined} onChange={changeHandler}/>
        :item ? item.area : undefined}
          </td>
      </tr>
        :null
      } 

      {
        report && report.load_location ? 
      <tr>
        <th className="tl">{report.load_location}</th>
        <td>
          {is_editable ? 
          <input autocomplete="off" type="text" className="col9" placeholder="◯◯市、◯◯区、など"  name="load_location" value={item ? item.load_location : undefined} onChange={changeHandler}/>
          :item ? item.load_location : undefined}
        </td>
      </tr>
       :null
      }


      {
        report && report.delivery_location ? 
      <tr>
        <th className="tl">{report.delivery_location}</th>
        <td>
        {is_editable ? 
          <input autocomplete="off" type="text" className="col9" placeholder="◯◯市、◯◯区、など" name="delivery_location" value={item ? item.delivery_location : undefined} onChange={changeHandler} />
        :item ? item.delivery_location : undefined}
          </td>
      </tr>
        :null
      }

      {
          report && report.requested_unit ? 
      <tr>
        <th className="tl">{report.requested_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●"  name="requested_unit" value={item ? item.requested_unit : undefined} onChange={changeHandler}/>
          :item ? item.requested_unit : undefined}
        <span className="unit">件</span></td>
      </tr>
          :null
        }

      {
          report && report.delivery_unit ? 
      <tr>
        <th className="tl">{report.delivery_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●"  name="delivery_unit" value={item ? item.delivery_unit : undefined} onChange={changeHandler}/>
          :item ? item.delivery_unit : undefined}
          <span className="unit">個</span>
        </td>
      </tr>
          :null
        }


        {
          report && report.return_requested_unit ? 
      <tr>
        <th className="tl">{report.return_requested_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●" name="return_requested_unit" value={item ? item.return_requested_unit : undefined} onChange={changeHandler}/>
          :item ? item.return_requested_unit : undefined}
          <span className="unit">件</span></td>
      </tr>
          :null
        }

      {
          report && report.return_unit ? 
      <tr>
        <th className="tl">{report.return_unit}</th>
        <td>
          {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●" name="return_unit" value={item ? item.return_unit : undefined} onChange={changeHandler}/>
          :item ? item.return_unit : undefined}
          <span className="unit">個</span></td>
      </tr>
          :null
        }

      {
          report && report.normal_load_unit ? 
      <tr>
        <th className="tl">{report.normal_load_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●" name="normal_load_unit" value={item ? item.normal_load_unit : undefined} onChange={changeHandler} />
          :item ? item.normal_load_unit : undefined}
          <span className="unit">個</span></td>
      </tr>
          :null
        }

      {
          report && report.normal_unit ? 
      <tr>
        <th className="tl">{report.normal_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●" name="normal_unit" value={item ? item.normal_unit : undefined} onChange={changeHandler} />
         :item ? item.normal_unit : undefined} 
          <span className="unit">個</span></td>
      </tr>
          :null
        }

      {
          report && report.nekopos_load_unit ? 
      <tr>
        <th className="tl">{report.nekopos_load_unit}</th>
        <td>
        {is_editable ?
        <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●"  name="nekopos_load_unit" value={item ? item.nekopos_load_unit : undefined} onChange={changeHandler}/>
        :item ? item.nekopos_load_unit : undefined}
        <span className="unit">個</span></td>
      </tr>
          :null
        }

     {
          report && report.nekopos_unit ? 
      <tr>
        <th className="tl">{report.nekopos_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●"  name="nekopos_unit" value={item ? item.nekopos_unit : undefined} onChange={changeHandler}/>
          :item ? item.nekopos_unit : undefined}
          <span className="unit">個</span></td>
      </tr>
          :null
        }


        {
          report && report.dm_load_unit ? 
      <tr>
        <th className="tl">{report.dm_load_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●" name="dm_load_unit" value={item ? item.dm_load_unit : undefined} onChange={changeHandler} />
          :item ? item.dm_load_unit : undefined}
          <span className="unit">個</span></td>
      </tr>
          :null
        }
        {
          report && report.dm_unit ? 
      <tr>
        <th className="tl">{report.dm_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●" name="dm_unit" value={item ? item.dm_unit : undefined} onChange={changeHandler} />
          :item ? item.dm_unit : undefined}
          <span className="unit">個</span></td>
      </tr>
          :null
        }

{
          report && report.code ? 
      <tr>
        <th className="tl">{report.code}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="text" className="col9" placeholder="●●" name="code" value={item ? item.code : undefined} onChange={changeHandler} />
          :item ? item.code : undefined}
          </td>
      </tr>
          :null
        }

{
          report && report.load_unit ? 
      <tr>
        <th className="tl">{report.load_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●" name="load_unit" value={item ? item.load_unit : undefined} onChange={changeHandler} />
          :item ? item.load_unit : undefined}
          <span className="unit">回</span></td>
      </tr>
          :null
        }

{
          report && report.night_unit ? 
      <tr>
        <th className="tl">{report.night_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()} className="col9" placeholder="●●"  name="night_unit" value={item ? item.night_unit : undefined} onChange={changeHandler}/>
          :item ? item.night_unit : undefined}
          <span className="unit">個</span></td>
      </tr>
          :null
        }
        
        {
          report && report.large_unit ? 
      <tr>
        <th className="tl">{report.large_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●" name="large_unit" value={item ? item.large_unit : undefined} onChange={changeHandler} />
          :item ? item.large_unit : undefined}
          <span className="unit">個</span></td>
      </tr>
          :null
        }

        

        {
          report && report.distance ? 
      <tr>
        <th className="tl">{report.distance}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●"  name="distance" value={item ? item.distance : undefined} onChange={changeHandler}/>
          :item ? item.distance : undefined}
          <span className="unit">km</span></td>
      </tr>
          :null
        }
        {
          report && report.waiting_hour ? 
      <tr>
        <th className="tl">{report.waiting_hour}</th>
        <td>
        {is_editable ?
          <select name="waiting_hour_h" value={item && item.waiting_hour ?  Math.floor(item.waiting_hour / 60) : undefined}  onChange={changeWaitingHHandler} >
            <option value="" selected="">選択してください</option>
            {
                config.get("REPORT_WAITING_HOUR").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
              }
          </select>
          :item && item.waiting_hour ?  Math.floor(item.waiting_hour / 60) : undefined}
        {is_editable ?
          <select name="waiting_hour_m" value={item && item.waiting_hour ?  item.waiting_hour - (60 * Math.floor(item.waiting_hour / 60)) : undefined}  onChange={changeWaitingHHandler} >
            <option value="" selected="">選択してください</option>
            {
                config.get("REPORT_WAITING_MIN").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
              }
          </select>
          :item && item.waiting_hour ?  item.waiting_hour - (60 * Math.floor(item.waiting_hour / 60)) : undefined}
          </td>
      </tr>
          :null
        }


      {
        report && report.received_gender ? 
      <tr>
        <th className="tl">{report.received_gender}</th>
        <td>
        {is_editable ?
          <select name="received_gender" value={item ? item.received_gender : undefined}  onChange={changeHandler} >
            <option value="" selected="">選択してください</option>
            {
                config.get("REPORT_GENDER").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
              }
          </select>
          :item ? item.received_gender : undefined}
        </td>
      </tr>
        :null
      }


      {
      report && report.received_name ? 
      <tr>
        <th className="tl">{report.received_name}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="text" className="col9" placeholder="◯◯" name="received_name" value={item ? item.received_name : undefined} onChange={changeHandler} />
          :item ? item.received_name : undefined}
          </td>
      </tr>
        :null
      }


      {
        item  && report && report.work_end_at ? 
      <tr>
        <th className="tl">{report.work_end_at}</th>
        <td clasName="row">{item.work_end_at && isDate(item.work_end_at) ? <span>{format(item.work_end_at ,"M月d日")}</span> : isDate(item.work_start_at) ? format(item.work_start_at ,"M月d日") : null}&nbsp;
        {is_editable ?
          <input type="time" className="w50 time" name="work_end_at" onChange={changeTimeEndHandler} value={item && item.work_end_at && isDate(item.work_end_at) ? format(item.work_end_at , "HH:mm") : undefined} />
          
          :item && item.work_end_at && isDate(item.work_end_at) ? format(item.work_end_at , "HH:mm") : undefined}
        </td>
      </tr>
        :null
      }
      
     
        {
          report && report.meter_start ? 
      <tr>
        <th className="tl">{report.meter_start}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●"  name="meter_start" value={item ? item.meter_start : undefined} onChange={changeHandler}/>
          :item ? item.meter_start : undefined}
          <span className="unit">km</span></td>
      </tr>
          :null
        }
        {
          report && report.meter_end ? 
      <tr>
        <th className="tl">{report.meter_end}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●" name="meter_end" value={item ? item.meter_end : undefined} onChange={changeHandler} />
          :item ? item.meter_end : undefined}<span className="unit">km</span></td>
      </tr>
          :null
        }
       
        {
          report && report.compleated_unit ? 
      <tr>
        <th className="tl">{report.compleated_unit}</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()} className="col9" placeholder="●●"  name="compleated_unit" value={item ? item.compleated_unit : undefined} onChange={changeHandler}/>
          :item ? item.compleated_unit : undefined}
          <span className="unit">個</span></td>
      </tr>
          :null
        }
       

       
       
      
        {
          report && report.fee   ? 
      <tr>
        <th className="tl">{report.fee}(ドライバー入力）</th>
        
          {
            item && !item.id ?
            <td>
              {is_editable ?
                <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="10000" name="fee" value={item ? item.fee : undefined} onChange={changeHandler} />
                :item ? item.fee : undefined}
                <span className="unit">円</span></td> 
            : <td>{item  && item.fee ? item.fee.toLocaleString() : undefined}円</td>
          }
          
        
      </tr>
          :null
        }
        { item && item.id && item.project.category !== 4  && item.project.category !== 6? //個数計算の場合はインプットできない
      <tr>
        <th className="tl">当日支払い単価</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}   className="col9" placeholder="●●●●" name="unit_price"  value={item.getRealFee() ? item.getRealFee() : undefined} onChange={changeFeeHandler} />
            :project_driver_item  && project_driver_item.fee_information && project_driver_item.fee_information.unit_price ?   
            getFee(project_driver_item.fee_information.unit_price ,item.fee_information.unit_price) : 
            item && item.fee_information ?  getFee(0 ,item.fee_information.unit_price) ? getFee(0 ,item.fee_information.unit_price).toLocaleString() : undefined :
            undefined 
          }
          
          <span className="unit">円</span>
        </td>
      </tr>
         :null}
        { item && item.id && (item.project.category === 4 || item.project.category === 6) ?
      <tr>
        <th className="tl">当日支払い単価</th>
        <td>
          {item ? item.getRealFee().toLocaleString() : null}
          <span className="unit">円</span>
        </td>
      </tr>
         :null}




          { item && item.id && report && report.cost?
      <tr>
        <th className="tl">高速代金</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}   className="col9" placeholder="●●●●" name="highway_fee" 
            value={getHighwayCost(item.receipts , item.fee_information  ? item.fee_information.highway_fee : 0) ? getHighwayCost(item.receipts , item.fee_information  ? item.fee_information.highway_fee : 0) : undefined} onChange={changeFeeHandler} />
            :getHighwayCost(item.receipts , item.fee_information  ? item.fee_information.highway_fee : 0) ? getHighwayCost(item.receipts , item.fee_information  ? item.fee_information.highway_fee : 0) : undefined}
            <span className="unit">円</span>
        </td>
      </tr>
        :null}

      { item && item.id && report &&  report.cost ?
      <tr>
        <th className="tl">駐車場代金</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●●●" name="parking_fee" 
            value={getParkingCost(item.receipts,  Number(item.fee_information ? item.fee_information.parking_fee : 0))  ? getParkingCost(item.receipts,  Number(item.fee_information ? item.fee_information.parking_fee : 0))  : undefined} onChange={changeFeeHandler} />
            :getParkingCost(item.receipts,  Number(item.fee_information ? item.fee_information.parking_fee : 0))  ? getParkingCost(item.receipts,  Number(item.fee_information ? item.fee_information.parking_fee : 0))  : undefined}
            <span className="unit">円</span>
        </td>
      </tr>
        :null}


      { item && item.id && report && report.cost?
      <tr>
        <th className="tl">その他代金</th>
        <td>
        {is_editable ?
          <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col9" placeholder="●●●●" name="other_fee" 
            value={getOtherCost(item.receipts, Number(item.fee_information ? item.fee_information.other_fee : 0)) ? getOtherCost(item.receipts, Number(item.fee_information ? item.fee_information.other_fee : 0)) : undefined} onChange={changeFeeHandler} />
            :getOtherCost(item.receipts, Number(item.fee_information ? item.fee_information.other_fee : 0)) ? getOtherCost(item.receipts, Number(item.fee_information ? item.fee_information.other_fee : 0)) : undefined}
            <span className="unit">円</span>
        </td>
      </tr>
        :null}


      { item && item.id && item.project.category !== 4 ?
      <tr>
        <th className="tl">残業時間</th>
       
          {
          report && report.project &&  report.project.overtime_fee_type > 0 ? 
          <td>
            <div><strong>残業時間</strong> {report.getOverTimeHourString(report.getOverTimeHour(item.work_start_at, item.work_end_at, item.fee_information.over_time))}</div>
            <div><strong>日報計算残業時間</strong>  {report.getOverTimeHourString(report.getOverTimeHour(item.work_start_at, item.work_end_at,0))}</div>
            <div><strong>追加</strong>  
            {
              is_editable && report.getOverTimeHourAddingSelector() ? 
              <span>&nbsp;
                <select autocomplete="off"  name="over_time" onChange={changeFeeHandler} >
                <option value={0}>追加時間</option>,
                  {report.getOverTimeHourAddingSelector(item.fee_information.over_time)}
                </select>
              </span>
              :
              <span>
                {is_editable ?
                <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col7" placeholder="●●●●" name="over_time" 
                  value={item.fee_information.over_time} onChange={changeFeeHandler} />
                  :item.fee_information.over_time}
                 <span className="unit">分</span>
              </span>
            }
            </div>
          </td>
          : <td>残業設定無し</td>}
      </tr>
        :null}


      { item && item.id && item.project.category !== 4 ?
      <tr>
        <th className="tl">超過距離</th>
        {
          report && report.project &&  report.project.distance_type > 0 ? 
          <td>
            <div><strong>超過距離</strong>  {report.getOverDistance(item.distance, item.fee_information.over_distance)}<span className="unit">km</span></div>
            <div><strong>日報計算超過距離</strong>  {report.getOverDistance(item.distance,0)}<span className="unit">km</span></div>
            {is_editable ? 
            <div><strong>追加</strong></div>
            : null}
            {is_editable ? 
            <input autocomplete="off" type="number"  onWheel={(e) => e.target.blur()}  className="col7" placeholder="●●●●" name="over_distance" 
              value={item.fee_information.over_distance} onChange={changeFeeHandler} />
              :item.fee_information.over_distance } 
            <span className="unit">km</span>
          </td>
          : <td>超過距離設定無し</td>}

       
      </tr>
        :null}
      
        
      <tr>
        <th className="tl">備考</th>
        <td>
        {is_editable ?
          <textarea type="text" className="col12" rows="5" name="note" value={item ? item.note : undefined} onChange={changeHandler} />
          :item ? item.note : undefined}
          </td>
      </tr>
      
     
      
    </tbody>
  </table>
   
  );
};

