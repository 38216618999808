


class ReportFormat  {




  getFormatFromList(list, target_id){
    return list.find(_item => _item.id === Number(target_id))
  }


}
export default ReportFormat

