import React, {useState, useEffect  } from 'react'
import { useSearchParams } from "react-router-dom";
import { PageApp } from '../../stories/PageApp'
import ProjectList from "./ProjectList"
import useProjectState from './useProjectState';
import { LoadingWindow } from '../../stories/components/loading_window/LoadingWindow';
import config from 'react-global-configuration';

function ProjectListApp(props:PropsValue) {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ?  searchParams.get("page") : 1 ;
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(null)
  const [list, setList] = useState([])
  const [total_number, setTotalNumber] = useState(1)
  const {project_list, project_search_limit, list_updated_at,project_total_number, getProjectList} = useProjectState()
  const [profile, setProfile] = useState(null)

  useEffect(() => {
  
    setLoading(<LoadingWindow closeHandler={loadingCloseHanlder} ></LoadingWindow>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(() => {
    setTotalNumber(project_total_number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_total_number])


  useEffect(() => {
    if(project_list){
      setList(project_list)
      setLoading(null)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])

  useEffect(() => {
    if(token){
      //GET LIST
      getProjectList(token,page,null,null,config.get("SEARCH_LIMIT"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])

  const loadingCloseHanlder = ()=>{
    setLoading(null)
  }

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }
  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  const searchHandler = (e) => {
    setLoading(<LoadingWindow closeHandler={loadingCloseHanlder} ></LoadingWindow>)
    getProjectList(token,1, null, e)
  }
  
  return(
    <div id="home">
      {loading}
      <PageApp current_page="project" content={<ProjectList  profile={profile}  token={token} projects={list} search_limit={project_search_limit} total_number={total_number} current_page={page} search={searchHandler} />} authChangeHandler={authChangeHandler} profileChangeHandler={profileChangeHandler}/>
    </div>
     
  )
}

export default ProjectListApp

