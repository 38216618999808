import React, {useState, useEffect  } from 'react'
import { useSearchParams } from "react-router-dom";
import { PageApp } from '../../stories/PageApp'
import {format,lastDayOfMonth} from 'date-fns';
import Driver from '../drivers/Driver';
import MonthlyReportList from "./MonthlyReportList"

import useMonthlyReportState from './useMonthlyReportState';
import useProjectDriverState from '../project/driver/useProjectDriverState';
import useProjectState from '../project/useProjectState';
import useDriverState from '../drivers/useDriverState';
import { LoadingWindow } from '../../stories/components/loading_window/LoadingWindow';
//import ErrorPage from '../../errorpage/ErrorPage';

function MonthlyReportListApp(props:PropsValue) {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ?  searchParams.get("page") : 1 ;
  const spot = searchParams.get("spot") ?  searchParams.get("spot") : 0 ;

 

  const [token, setToken] = useState(null)
  const today = new Date()
  const with_spot = spot === "1" ? true : false
  const [driver_id,setDriverId] = useState(searchParams.get("driver_id") )
  const [client_id,setClientId] = useState(searchParams.get("client_id") )
  const [partner_id,setPartnerId] = useState(searchParams.get("partner_id") )
  const [project_id,setProjectId] = useState(null)
  
  const [year,setYear] = useState(searchParams.get("year")  ? searchParams.get("year")  : today.getFullYear())
  const [month,setMonth] = useState(searchParams.get("month")  ? searchParams.get("month")  : today.getMonth() + 1)
  const [profile, setProfile] = useState(null)
  const [saved_params, setSavedParams] = useState({year:year, month:month,driver_id:driver_id,client_id:client_id,partner_id:partner_id})
  const {my_project_list, total_project_driver_number, getMyProjectList} = useProjectDriverState()
  const {project_list, getProjectList} = useProjectState()
  const {saveSearchParams, loadSearchParams, deleteSearchParams} = useMonthlyReportState()
  const {driver_list,  getDriverList} = useDriverState()
  const [loading, setLoading] = useState(<LoadingWindow />)
  useEffect(() => {
    if(my_project_list){
  
      const driver_ids = my_project_list.map(item => item.driver.id)
      getProjectList(token, 1, driver_ids, null,null,true)
      setLoading(null)
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[my_project_list])




  useEffect(() => {
 
    if(token && profile && profile.id){
      const params = loadSearchParams()
     
      if(params && !searchParams.get("year") && !searchParams.get("month") && !searchParams.get("driver_id") && !searchParams.get("client_id") && !searchParams.get("partner_id") ){
        const target_year =   params.year ? params.year : year
        const target_month =  params.month ? params.month : month 
        const search_driver_id = driver_id ? driver_id : params.driver_id
        setSavedParams(params)
       
        setYear(target_year)
        setMonth( target_month)

        //TODO LIST 分ける
        getMyProjectList(token, format(lastDayOfMonth(new Date(target_year, target_month-1, 1)), "yyyy-MM-dd"),params.client_id, params.project_id > 0 ? params.project_id : null, params.partner_id, search_driver_id, with_spot, 0,false,false, 30, page)
       
        //TODO LIST 分ける
        if(profile.user_type !== "partner" ){
          getDriverList(token,1, null, new Driver({is_internal:1} ), null, null, format(new Date(target_year, target_month-1, 1), "yyyy-MM-dd"), format(lastDayOfMonth(new Date(target_year, target_month-1, 1)), "yyyy-MM-dd"))
        }
        
      }else{
        setYear(year)
        setMonth( month)

        //TODO LIST 分ける
        getMyProjectList(token, format(lastDayOfMonth(new Date(year, month-1, 1)), "yyyy-MM-dd"),client_id,null,partner_id,driver_id,with_spot,0,false,false, 30, page)
        //TODO LIST 分ける
        if(profile.user_type !== "partner" ){
          getDriverList(token,1, null, new Driver({is_internal:1}),null, null, format(new Date(year, month-1, 1), "yyyy-MM-dd"), format(lastDayOfMonth(new Date(year, month-1, 1)), "yyyy-MM-dd"))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token, profile])



  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  const searchHandler = (e) => {
    
    //getMontylyReportList(token,null,null, 1, Number(e.year), Number(e.month))
    setYear(Number(e.year))
    setMonth( Number(e.month))
    setDriverId(e.driver_id)
    setClientId(e.client_id)
    setPartnerId(e.partner_id)
    setProjectId(e.project_id)
    saveSearchParams({year:e.year, month:e.month, client_id:e.client_id, project_id:e.project_id, partner_id:e.partner_id, driver_id:e.driver_id})
    setSavedParams({year:e.year, month:e.month, client_id:e.client_id, project_id:e.project_id, partner_id:e.partner_id, driver_id:e.driver_id})
    setLoading(<LoadingWindow />) 

    //TODO わける
    getMyProjectList(token, format(lastDayOfMonth(new Date(Number(e.year),  Number(e.month) - 1, 1)), "yyyy-MM-dd"),e.client_id, e.project_id > 0 ? e.project_id : null, e.partner_id, e.driver_id, with_spot, 0, false, false, 30, page)
    getDriverList(token,1, null, new Driver({is_internal:1}),null, null, format(new Date(e.year, e.month-1, 1), "yyyy-MM-dd"), format(lastDayOfMonth(new Date(e.year, e.month-1, 1)), "yyyy-MM-dd"))
    
  }

  const profileChangeHandler = (e) => {
  
    setProfile(e)

    if(!e.isFinanceManager()){
      setLoading(null)
    }
  }
  
  const deleteSearchParamsHandler = () => {
    deleteSearchParams()
  }
 
  
  return(
    <div id="home">
      <div>{loading}</div>
      
       <PageApp  current_page="monthly_report" 
        content={
         
              <MonthlyReportList profile={profile} project_id={project_id} driver_id={driver_id  && driver_id ? driver_id: saved_params ? saved_params.driver_id  :null} internal_drivers={driver_list}  token={token} list={my_project_list} total_number={total_project_driver_number} projects={project_list}  year={year} month={month} params={saved_params} current_page={page} with_spot={with_spot} search_limit={30} search={searchHandler} deleteSearchParams={deleteSearchParamsHandler} />
           
        }
        authChangeHandler={authChangeHandler} profileChangeHandler={profileChangeHandler}  
      /> 
      

    </div>
     
  )
}

export default MonthlyReportListApp

