
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../../action/Action';
const DeiverAction = {


  getList(token, offset, limit,partner_id, item, with_deleted, with_spot, start_date, end_date, callback, errorHandler){
    const data = new FormData() 
 
    data.append('limit', limit)
    data.append('offset', offset)
    if(partner_id )
      data.append('partner_id', partner_id)
    if(item && item.partner_id )
      data.append('partner_id', item.partner_id)
    if(item && item.name && item.name !== "")
      data.append('name', item.name)
    if(item && item.user_id )
      data.append('user_id', item.user_id)
    if(item && item.is_internal !== null && item.is_internal !== undefined)
      data.append('company_driver', item.is_internal)
    if(with_deleted !== null )
      data.append('with_deleted', with_deleted)
    if(with_spot !== null )
      data.append('with_spot', with_spot)
    if(start_date !== null )
      data.append('target_date_start', start_date)
    if(end_date !== null )
      data.append('target_date_end', end_date)

   
    if(item  && item.tel && item.tel !== "")
      data.append('tel', item.tel)
    if(item  && item.car_no && item.car_no !== "")
      data.append('car_no', item.car_no)
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_DRIVER") + "?"  + queryString

    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },
  

  getDetail(token, id,  callback, errorHandler){
    Action.fetchAuthorizedJson( config.get("API_PATH") + config.get("API_DRIVER")  + id  , token, "GET", {}, null,  callback, errorHandler)
  },

  getDetailInPublic( id,  callback, errorHandler){
    Action.fetchJson( config.get("API_PATH") + config.get("API_DRIVER")  + id  + "/pub" , "GET", {}, null,  callback, errorHandler)
  },

  create( token, item,  callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const url = item.id ? config.get("API_PATH") + config.get("API_DRIVER") + item.id  : config.get("API_PATH") + config.get("API_DRIVER")
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
  },

  delete( token, id,  callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_DRIVER") + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },

  saveOnCookie(item){
    cookie.save('driver_form',item, { path: '/' })
  },

  hasCookie(item, callback, errorHandler){
    const driver =  cookie.load("driver_form")
    if(driver) return true 
    return false
  },

  loadFromCookie( callback, errorHandler){
    const driver =  cookie.load("driver_form")
    console.log(driver)
    if(driver) {
      callback({data:driver})
    }else{
      errorHandler({"error":"not found"})
    }
  },
  removeCookie(item, callback, errorHandler){
    cookie.remove('driver_form', { path: '/' })
  }
 
 
}

export default DeiverAction
