import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import MonthlyReportDetail from "./MonthlyReportDetail"
import ReportProps from '../report/ReportProps';
import useReportState from '../report/useReportState';
import useProjectState from '../project/useProjectState';
import useMonthlyReportState from './useMonthlyReportState';
import useProjectDriverState from '../project/driver/useProjectDriverState';
import useDriverState from '../drivers/useDriverState';
import { NonUserPageApp } from '../../stories/NonUserPageApp';
import {  parseISO} from "date-fns"

function MonthlyReportDetailApp(props:PropsValue) {
  const { id } = useParams();
  const { project_id } = useParams();
  const { project_driver_id } = useParams();
  const { driver_id } = useParams();
  const { year } = useParams();
  const { month } = useParams();
  
  const [profile, setProfile] = useState(null)
  const [editable, setEditable] = useState(false)
  const [token, setToken] = useState(null)
  const [list, setList] = useState(null)

  const { report_list, list_updated_at} = useReportState()
  const {project, getProject} = useProjectState()
  const {project_driver, getProjectDriver} = useProjectDriverState()
  const { monthly_report_created, createMonthlyReport} = useMonthlyReportState()
  const {driver, getDriver} = useDriverState()


  useEffect(() => {

    if(id === "pub"){    
      getProject("token", project_id)
     if(project_driver_id){
        getProjectDriver("token",project_id, project_driver_id)
      }else if(driver_id){
        getDriver("token", driver_id)
      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id])


  useEffect(() => {
   
    if(token){    
      //getProject(token, project_id)

      if(project_driver_id){
        getProjectDriver(token,project_id, project_driver_id)
      }else if(driver_id){
        getDriver(token, driver_id)
      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  

 

  useEffect(() => {
    if(report_list){
      
      setList(report_list.map(item =>{

        var  _repo = new ReportProps(item)
        _repo = _repo.set("work_start_at", parseISO(item.work_start_at))
        _repo = _repo.set("work_end_at", parseISO(item.work_end_at))
        return _repo
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])
  

  useEffect(() => {

    if(driver ){
      getProject(token, project_id)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ driver])

  useEffect(() => {

    if(project_driver ){
      getProject(token, project_driver.project_id)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ project_driver])



  useEffect(() => {

    if(monthly_report_created && monthly_report_created.id ){ 
      window.location.href  = "/monthly_report"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[monthly_report_created])


  useEffect(() => {

    if(profile){ 
      if(id !== "pub" && profile.user_type !== "partner"){
        setEditable(true)
      }else{
        setEditable(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile])

  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (repot_list, monthly_props) => {
    createMonthlyReport(token, project_id, 
      project_driver.driver.uid, 
      project_driver.uid,
      {
        year:Number(year),
        month:Number(month), 
        report_type:1, 
        content:JSON.stringify(repot_list),
        unit_price:project_driver.unit_price,
        over_time_unit_fee:project_driver.over_time_unit_price,
        over_time_min:monthly_props ? monthly_props.over_time_min : null,
        over_distance_unit_fee:project_driver.over_distance_unit_price,
        over_distance:monthly_props ? monthly_props.over_distance : null,
        tatekae:monthly_props ? monthly_props.tatekae : null
      }
    )
  }

  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  return(
    <div id="home">
      {
        id === "pub" ?  <NonUserPageApp content={<MonthlyReportDetail btn={false} token={token} profile={profile} year={year} month={month}  project={project} driver={project_driver ? project_driver.driver : driver ? driver : null} report_list={list}  id={id} updateHandler={updateHandler}/>} profileChangeHandler={profileChangeHandler} authChangeHandler={authChangeHandler} /> 
        :<PageApp current_page="monthly_report" content={<MonthlyReportDetail btn={editable} project_driver_id={project_driver_id}  token={token} profile={profile} year={year} month={month}  project={project} driver={project_driver ? project_driver.driver : driver ? driver : null} report_list={list}  id={id}  updateHandler={updateHandler}/>} profileChangeHandler={profileChangeHandler} authChangeHandler={authChangeHandler} /> 
      }
     
    </div>
     
  )
}

export default MonthlyReportDetailApp

