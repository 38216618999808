import { useState } from 'react';
import ReportFormatAction from './ReportFormatAction';

function useReportFormatState() {

  
  const [error, setError] = useState(null)
  const [list, setList] = useState([])
  const [list_updated_at, setListUpdatedAt] = useState(null)


  const listLoadeddHandler = (data) => {
   
    setList(data.data)
    setListUpdatedAt(new Date())
  }

  
  const errorHandler = (data) => {
    setError(data)
  }

  const getList = (token) => {
    ReportFormatAction.getList(token, listLoadeddHandler, errorHandler)
  }

  return { 
    report_format_list:list, report_format_list_updated_at:list_updated_at,  error,
    getReportFormatList:getList,
  };
}

export default useReportFormatState