import React, { useState, useEffect } from 'react'
import config from 'react-global-configuration';
import ProjectDetailItem from './ProjectDetailItem'
import useClientState from '../client/useClientState'
import ProjectDetailLinks from './ProjectDetailLinks'
import useReportFormatState from '../monthly_report/format/usReportFormatState';
function ProjectDetailOverlay(props:PropsValue) {
  
  const [formats, setFormats] = useState(null)
  const {client, getClient} = useClientState()
  const{report_format_list,report_format_list_updated_at, getReportFormatList} = useReportFormatState()
  useEffect(() => {

    getClient(props.token,props.project.client_id)
    getReportFormatList(props.token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[client])


  useEffect(() => {
    if(report_format_list_updated_at && report_format_list){
      setFormats(report_format_list)
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_format_list_updated_at])

  const btnClickHandler = () => {
    window.location.href = "/project/" + props.project.id  + "?new=1"
  }

  const btnDeleteHandler = () => {
    window.location.href = "/project/" + props.project.id + "/delete"
  }

  return(
    <div className="modal-window" data-id="modal1" >
    <button className="js-close button-close" onClick={props.closeHandler}>Close</button>
        <div className="modal-scroll__container">
          <div className="column-detail">
            <div className="column-left">
              <ProjectDetailItem project={props.project}  formats={formats} clients={client ? [client] : null} is_edit={false}/>
            </div>
              <ProjectDetailLinks project={props.project} />
          </div>
          { props.profile && props.profile.user_type === config.get("USER_FINANCE") ? 
            <div className="button_wrap center">
              <button className="textbutton" onClick={btnDeleteHandler}>削除</button>
              <button className="red" type="button" onClick={btnClickHandler}>編集</button>
            </div>
            : null
          }
        </div>
  </div>
  
    
  )
}

export default ProjectDetailOverlay   

