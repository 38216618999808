import React, { useState } from 'react'
import { PageApp } from '../../stories/PageApp'
import icon_dayreport from "../../images/icon_dayreport.png"
import icon_reports from "../../images/icon_reports.png"
import icon_case from "../../images/icon_case.png"
import icon_client from "../../images/icon_client.png"
import icon_subcontractor from "../../images/icon_subcontractor.png"
import icon_driver from "../../images/icon_driver.png"
import icon_user from "../../images/icon_user.png"


function DashboardApp(props:PropsValue) {

  //const [content, setContent] = useState()
  const [profile, setProfile] = useState(null)
  const FINANCE = "finance"

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      //setToken(e.token)
    }
  }
  const profileChangeHandler = (e) => {

    if(e.user_type === "partner"){
      window.location.href = "/report"
    }
    setProfile(e)
  }
  return(
    <div id="home">
      <PageApp profileChangeHandler={profileChangeHandler} content={

<div className="inner">
<div className="common_links product_links">
    <h2><span><img src={icon_dayreport} alt="日報管理" /></span>日報管理</h2>
    <ul className="links row between">
        <li>
            <a href="/report">
                <div className="row align_center">
                    <div className="left">
                        <p>日報確認チェック</p>
                    </div>
                  
                    <div className="right">
                       <p>日報が上がっているか<br/>
                           チェックする場合</p>
                    </div>
                
                </div>
            </a>
        </li>
      
        <li>
            <a href="/report">
                <div className="row align_center">
                    <div className="left">
                        <p> 業務日報確認</p>
                    </div>
                    <div className="right">
                    <p>日報の確認や編集を<br/>
                            する場合</p>
                    </div>
                </div>
            </a>
        </li>
       
    </ul>
</div>

<div className="common_links product_links">
    <h2><span><img src={icon_reports} alt="帳票管理" /></span>帳票管理</h2>
    <ul className="links row between">
        <li>
            <a href="/monthly_report">
                <div className="row align_center">
                    <div className="left">
                        <p> 月報</p>
                    </div>
                    <div className="right">
                        <p>月報を確認・出力<br/>
                            する場合</p>
                    </div>
                </div>
            </a>
        </li>
      
        <li>
            <a href="/monthly_payment">
                <div className="row align_center">
                    <div className="left">
                        <p>支払明細書</p>
                    </div>
                    <div className="right">
                        <p>支払明細書を確認・<br/>
                            出力する場合</p>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>


<div className="common_links product_links">
    <h2><span><img src={icon_case} alt="案件管理" /></span>案件管理</h2>
    <ul className="links row between">
        <li>
            <a href="/project/new?new=1">
                <div className="row align_center">
                    <div className="left">
                        <p>案件登録</p>
                    </div>
                    <div className="right">
                        <p>案件情報を新規登録<br/>
                            する場合</p>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="/project">
                <div className="row align_center">
                    <div className="left">
                        <p>案件一覧</p>
                    </div>
                    <div className="right">
                        <p>案件一覧を表示<br/>
                            する場合</p>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>

<div className="common_links product_links">
    <h2><span><img src={icon_client} alt="クライアント管理" /></span>クライアント管理</h2>
    <ul className="links row between">
        <li>
            <a href="/client/new?new=1">
                <div className="row align_center">
                    <div className="left">
                        <p>クライアント登録</p>
                    </div>
                    <div className="right">
                        <p>クライアント情報を新規登録<br/>
                            する場合</p>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="/client">
                <div className="row align_center">
                    <div className="left">
                        <p>クライアント一覧</p>
                    </div>
                    <div className="right">
                        <p>クライアント情報一覧を<br/>
                            表示する場合</p>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>

<div className="common_links product_links">
    <h2><span><img src={icon_subcontractor} alt="依頼先管理" /></span>依頼先管理</h2>
    <ul className="links row between">
        <li>
            <a href="/partner/new?new=1">
                <div className="row align_center">
                    <div className="left">
                        <p>依頼先登録</p>
                    </div>
                    <div className="right">
                        <p>依頼先情報を新規登録<br/>
                            する場合</p>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="/partner">
                <div className="row align_center">
                    <div className="left">
                        <p>依頼先一覧</p>
                    </div>
                    <div className="right">
                        <p>依頼先情報一覧を<br/>
                            表示する場合</p>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>

<div className="common_links product_links">
    <h2><span><img src={icon_driver} alt="ドライバー管理" /></span>ドライバー管理</h2>
    <ul className="links row between">
        <li>
            <a href="/driver/new">
                <div className="row align_center">
                    <div className="left">
                        <p>ドライバー登録</p>
                    </div>
                    <div className="right">
                        <p>ドライバー情報を新規登録<br/>
                            する場合</p>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="/driver">
                <div className="row align_center">
                    <div className="left">
                        <p>ドライバー一覧</p>
                    </div>
                    <div className="right">
                        <p>ドライバー情報一覧を<br/>
                            表示する場合</p>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>
{
                          profile && profile.user_type === FINANCE ? 
<div className="common_links product_links">
    <h2><span><img src={icon_user} alt="ユーザー管理" /></span>ユーザー管理</h2>
    <ul className="links row between">
        <li>
            <a href="/user/new?new=1">
                <div className="row align_center">
                    <div className="left">
                        <p>ユーザー登録</p>
                    </div>
                    <div className="right">
                        <p>ユーザー情報を新規登録<br/>
                            する場合</p>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="/user">
                <div className="row align_center">
                    <div className="left">
                        <p>ユーザー一覧</p>
                    </div>
                    <div className="right">
                        <p>ユーザー情報一覧を<br/>
                            表示する場合</p>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>
:null}

</div>

      } current_page="index" title="ダッシュボード" authChangeHandler={authChangeHandler}/>
    </div>
     
  )
}

export default DashboardApp

