
import { addMonths, format, setDate,endOfMonth } from 'date-fns';
import PartnerProps from './PartnerProps'

const props = extendValues => class extends PartnerProps {};
class Partner extends props({}) {

  getPaymentExecuteDate(target_date) {
   var pay_date = null 
   switch(this.payment_day_type) {
    case 1 : pay_date = setDate(addMonths(target_date,1),10)
      break
    case 2 : pay_date = setDate(addMonths(target_date,1),15)
      break
    case 3 : pay_date = setDate(addMonths(target_date,1),20)
      break
    case 4 : pay_date = setDate(addMonths(target_date,1),25)
      break
    case 5 : pay_date = endOfMonth(addMonths(target_date,1))
      break
    case 6 : pay_date = setDate(addMonths(target_date,2),10)
      break
    case 7 : pay_date = setDate(addMonths(target_date,2),15)
      break
    case 8 : pay_date = setDate(addMonths(target_date,2),20)
      break
    case 9 : pay_date = setDate(addMonths(target_date,2),25)
      break
    case 10 : pay_date = endOfMonth(addMonths(target_date,2))
      break
    case 11 : pay_date = setDate(addMonths(target_date,2),5)
      break
    default: {}
   }

    return pay_date ? format(pay_date, "yyyy年M月d日") : "-"
  }

}
export default Partner


