import React, { useEffect, useState,  } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import Utile from '../../utiles/Utile';

import useClientState from '../client/useClientState';

//Format
import useReportFormatState from '../monthly_report/format/usReportFormatState';
import ReportFormat from '../monthly_report/format/ReportFormat';

function ProjectConfirm(props:PropsValue) {
  const report_format = new ReportFormat()
  const [item, setItem] = useState(null)
  const [format_label, setFormatLabel] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  const [clientObject, setClientObject] = useState(null)
  const {client, getClient} = useClientState()
  const [title, setTitle] = useState(null)
  const{report_format_list, report_format_list_updated_at, getReportFormatList} = useReportFormatState()
  useEffect(() => {
    if(props.project && props.token){

      setItem(props.project)
      getClient(props.token, props.project.client_id)
      setTitle(props.project.id ? "案件情報" + config.get("EDIT") : "新規案件" + config.get("REGIST"))
      getReportFormatList(props.token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project,props.token])


  useEffect(() => {
    if(item && report_format_list_updated_at && report_format_list){
      const format = report_format.getFormatFromList(report_format_list,item.report_type )
      if(format){
        setFormatLabel(format.name)
      }
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_format_list_updated_at])



  useEffect(() => {
    setClientObject(client)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[client])

  useEffect(() => {
    if(props.error)
      setErrorMessage("エラーが発生しました。")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.error])


  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler(item)
  }

  const backHandler = () => {
   const url = item.id ? "/project/" + item.id : "/project/new"
   window.location.href = url
  }

  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
              <h2 className="page_title">{title}</h2>
                <Breadcrumbs pages={[new LinkProps("案件一覧","/project"),new LinkProps(title,"#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">{title} 内容確認</p>
                  {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
									<div className="input_wrap">
                  <table className="input_table col12 login_tbl">
											<tbody>
												
												<tr>
													<th className="tl">クライアント名</th>
													<td>{item && clientObject ?clientObject.name:null}</td>
												</tr>
												<tr>
													<th className="tl">案件名</th>
													<td>{item?item.name:null}</td>
												</tr>
											
												<tr>
													<th className="tl">種類</th>
													<td>{item && item.category ? Utile.getLabelByValue(config.get("PROJECT_CATEGORY"),Number(item.category)):null}</td>
												</tr>
												<tr>
													<th className="tl">締め日</th>
													<td>{item && item.closing_date ? Utile.getLabelByValue(config.get("PROJECT_CLOSING_DATE_TYPE"),Number(item.closing_date)):null}</td>
												</tr>
												<tr>
													<th className="tl">月報ひな形</th>
													<td>{format_label}</td>
												</tr>
												<tr>
													<th className="tl">残業設定</th>
													<td>{item && item.overtime_fee_type ? Utile.getLabelByValue(config.get("PROJECT_OVERTIME_TYPE"),Number(item.overtime_fee_type)):null}</td>
												</tr>
												<tr>
													<th className="tl">距離設定</th>
													<td>{item && item.distance_type ? Utile.getLabelByValue(config.get("PROJECT_DISTANCE_TYPE"),Number(item.distance_type)):null}</td>
												</tr>
												<tr>
													<th className="tl">勤務時間設定</th>
													<td>{item && item.working_time_type ? Utile.getLabelByValue(config.get("PROJECT_WORKING_TIME_TYPE"),Number(item.working_time_type)):null}</td>
												</tr>
												<tr>
													<th className="tl">時間設定開始時刻</th>
													<td>{item?item.overtime_start_at:null}</td>
												</tr>
												<tr>
													<th className="tl">時間設定終了時刻</th>
													<td>{item?item.overtime_close_at:null}</td>
												</tr>
												<tr>
													<th className="tl">拘束時間設定</th>
													<td>{item?item.time_set:null}</td>
												</tr>

												<tr>
													<th className="tl">備考</th>
													<td>{item?item.note:null}</td>
												</tr>
											</tbody>
										</table>
                 
									</div>
								
                </div>
                <div className="button_wrap center">
								
                    <button className="red" type="button" onClick={btnClickHandler}>  { item && item.id ? "この情報で"  + config.get("EDIT") :"この情報で" + config.get("REGIST")} </button>
               
							  </div>

                <div className="button_wrap center">
								  <button className="red" type="button" onClick={backHandler}>入力に戻る</button>
							  </div>


                </div> 
				</div>  
      
		</div>

  )
}

export default ProjectConfirm

