import { useState } from 'react';
import ClientAction from './ClientAction';
import config from 'react-global-configuration';

function useClientState() {


  const [limit, setLimit] = useState(config.get("SEARCH_LIMIT"))
  
  const [error, setError] = useState(null)  
  const [created, setCreated] = useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState(null)
  const [total_number, setTotalNumber] = useState(null)
  const [list_updated_at, setListUpdatedAt] = useState(null)

  const listLoadeddHandler = (data) => {
    setList(data.data)
    setTotalNumber(data.headers.get("total-number"))
    setListUpdatedAt(new Date())

  }
  const loadedHandler = (data) => {
    setItem(data.data)
  }
  const createdHandler = (data) => {
    setItem(data.data)
    setCreated(data.data)
  }

  const errorHandler = (data) => {
    setError(data)
  }
  const hasCookie = () => {
    return ClientAction.hasCookie()
  }

  const saveOnCookie = (item) => {
    ClientAction.saveOnCookie(item)
  }

  const loadFromCookie = () => {
    ClientAction.loadFromCookie( loadedHandler, errorHandler)
  }
  
  const removeCookie = () => {
    ClientAction.removeCookie()
  }

  const getList = (token, page, item) => {
    const offset = (page - 1)  * limit 
    ClientAction.getList(token, item, offset,limit, listLoadeddHandler, errorHandler)
  }


  const create = (token, item) => {
    ClientAction.create(token, item, createdHandler, errorHandler)
  }

  const getDetail = (token, id) => {
    ClientAction.getDetail(token, id, loadedHandler, errorHandler)
  }

  const deleteItem = (token, id) => {
    ClientAction.delete(token, id, createdHandler, errorHandler)
  }



  return { client:item, client_list:list, list_updated_at:list_updated_at, client_error:error, client_deleted:created, client_created:created, client_total_number:total_number, client_search_limit:limit,
    getClientList:getList, getClient:getDetail, saveOnCookie:saveOnCookie, hasCookie:hasCookie, removeCookie:removeCookie, loadFromCookie:loadFromCookie, createClient:create, deleteClient:deleteItem,
    setClientSerachLimit:setLimit};
}

export default useClientState