
import MontylyPaymentProps from './MontylyPaymentProps'


const projectProps = extendValues => class extends MontylyPaymentProps {};
class MonthlyPayment extends projectProps({}) {

    getWorkedDaysByDriver(driver_id){
      //const contents_obj = JSON.parse(this.content)
      return 0
    }

    getTotalWorlingPrice = (items) => {
      var total_target = 0
      for(var i = 0; i < items.length; i++){
        const item = items[i]
        total_target +=  item.getTotal()
      }
      return total_target
    }
    
    getCommition = (driver, partner) => {
      if(driver && driver.commision){
        return driver.commision
      }else if(partner && partner.commision){
        return partner.commision
      }
      else{
        return 0
      }
    }

    getCommitionPrice = (target_price, driver, partner) => {
      const commition_rate = this.getCommition(driver, partner)
      return Math.round(( commition_rate / 100) * target_price)
    }




    getTotalTaxTargetPrice = (total_target_price, commition_price) => {
      return total_target_price - commition_price
    }
    
    getNonTaxByTotalPrice = (params) => {
    
      return  (
        + Number(params.energy_money ? params.energy_money : 0)
        + Number(params.highway_cost) 
        + Number(params.parking_cost)
        + Number(params.other_cost))

    }  
    
   

   getTaxByTotalPrice = (target_price,partner) => {
    
    if(partner && Number(partner.tax_include) === 1){
      return Math.round((target_price / 110)*10)
    }else if(partner && Number(partner.tax_include) === 2){
      return Math.round(target_price * 0.1)
    }else {
      return Math.round((target_price / 110)*10)
    }
  }

  getTotalWithTax  = (target_price, partner, params) => {
    const non_tax_total = this.getNonTaxByTotalPrice(params)

    if(partner && Number(partner.tax_include) === 1){
      return non_tax_total + target_price
    }else if(partner && Number(partner.tax_include) === 2){
      return non_tax_total + target_price + this.getTaxByTotalPrice(target_price, partner)
    }else {
      return non_tax_total + target_price
    }
  }

}
export default MonthlyPayment
